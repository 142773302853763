import * as yup from "yup";

import React, { useContext, useState } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import { Formik } from "formik";
import InvalidFieldError from "../../shared/invalid-field-error";
import { Modal } from "react-bootstrap";
import { SendOTP } from "../../services/cmsService";
import Swal from "sweetalert2";
import { localStrings as local_Strings } from "../../translations/localStrings";
import xIcon from "../../images/x-icon.svg";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const eye = <FontAwesomeIcon icon={faEye} />;

interface iForgotPasswordStep3 {
  showForgotPasswordStep3Modal: boolean;
  hideForgotPasswordStep3Modal: () => void;
  backForgotPasswordStep2Modal: () => void;
  showForgotPasswordStep4Modal: (password?: string) => void;
  customerId: string;
}

function ForgotPasswordStep3(props: iForgotPasswordStep3) {
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);
  const [isLoading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const toggleNewPasswordVisiblity = () => {
    setShowNewPassword(showNewPassword ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(showConfirmPassword ? false : true);
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
    cif: props.customerId,
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(local_Strings.ChangePassword_RequiredMsg)
      .min(7, local_Strings.ChangePassword_InvalidationMsg)
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&_()+=-])(?=.{7,})/,
        local_Strings.ChangePassword_InvalidationMsg
      ),
    confirmPassword: yup
      .string()
      .required(local_Strings.ChangePassword_ConfirmRequiredMsg)
      .oneOf(
        [yup.ref("password"), ""],
        local_Strings.ChangePassword_MustMatchMsg
      ),
  });

  return (
    <Modal
      show={props.showForgotPasswordStep3Modal}
      onHide={props.hideForgotPasswordStep3Modal}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="myModal"
    >
      <Modal.Header>
        <div className="modal-header-text">
          <div className="d-flex align-items-center">
            <div className="ib-icon">
              <a
                href="#"
                onClick={props.backForgotPasswordStep2Modal}
                className="backToAccountsList"
              >
                <i className="fa fa-chevron-left"></i>
              </a>
            </div>
            <div className="ib-text">
              <h4 id="newReqTxt">{local_Strings.PasswordResetRequestTitle}</h4>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="close"
          onClick={props.hideForgotPasswordStep3Modal}
        >
          <img src={xIcon} width="15" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="box modal-box p-4  scrollabel-modal-box">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              const optResult = await SendOTP(props.customerId);
              props.showForgotPasswordStep4Modal(values.password);
              setLoading(false);
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isValid,
              validateForm,
            }) => (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <h5>{local_Strings.PasswordResetRequestHint}</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 form-group">
                    <label>
                      {local_Strings.PasswordResetRequestEnterPassword}
                    </label>
                    <div className="pass-wrapper">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        value={values.password || ""}
                        onBlur={handleBlur("password")}
                        onChange={handleChange("password")}
                      />
                      <i onClick={toggleNewPasswordVisiblity}>{eye}</i>{" "}
                    </div>
                    {touched.password &&
                      errors.password &&
                      InvalidFieldError(errors.password)}
                  </div>
                  <div className="col-lg-12 form-group">
                    <label>
                      {local_Strings.PasswordResetRequestResendConfirmPassword}
                    </label>
                    <div className="pass-wrapper">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        value={values.confirmPassword || ""}
                        onBlur={handleBlur("confirmPassword")}
                        onChange={handleChange("confirmPassword")}
                      />
                      <i onClick={toggleConfirmPasswordVisiblity}>{eye}</i>{" "}
                    </div>
                    {touched.confirmPassword &&
                      errors.confirmPassword &&
                      InvalidFieldError(errors.confirmPassword)}
                  </div>
                </div>
                <div className="text-xs color-grey">
                  {local_Strings.ChangePassword_InvalidationMsg}
                </div>
                <div className="text-right py-3 ">
                  <button
                    id="applyReqBtn"
                    className="btn btn-primary"
                    type="submit"
                    onClick={(e) => {
                      validateForm(values);
                      if (isValid) {
                        handleSubmit();
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: local_Strings.formValidationMessage,
                          showConfirmButton: false,
                          showCancelButton: true,
                          cancelButtonText: local_Strings.WelcomeScreenClose,
                        });
                        touched.password = true;
                        touched.confirmPassword = true;
                      }
                    }}
                  >
                    {local_Strings.PasswordResetButton}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPasswordStep3;
