import React, { useContext, useState } from "react";
import * as helper from "../../../Helpers/helper";
import guarantiesIcon from "../../../images/guaranties-icon.svg";
import { PortfolioContext } from "../../../pages/Homepage";
import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import { localStrings as local_Strings } from "../../../translations/localStrings";
import GuaranteesDetails from "./GuaranteesDetails";
import GuaranteesListing from "./GuaranteesListing";

function Guarantees() {
  const currentContext = useContext(AuthContext);
  const userPortfolio = useContext(PortfolioContext);
  const [params, setReferenceId] = useState({ gurRef: "", balance: "0" });
  const [showGuaranteesListing, setShowGuaranteesListing] = useState(false);

  const handleCloseGuaranteesListing = () => {
    setShowGuaranteesListing(false);
  };
  const handleShowGuaranteesListing = () => {
    if (
      userPortfolio.totalGuarantees &&
      parseInt(userPortfolio.totalGuarantees) !== 0
    ) {
      setShowGuaranteesListing(true);
    }
  };

  const [showGuaranteesDetails, setshowGuaranteesDetails] = useState(false);

  const handleCloseGuaranteesDetails = () => setshowGuaranteesDetails(false);
  const handleBackGuaranteesDetails = () => {
    setshowGuaranteesDetails(false);

    setShowGuaranteesListing(true);
  };

  return (
    <div>
      <div className="inner-box">
        <div className="d-flex align-items-center">
          <div className="ib-icon">
            <img src={guarantiesIcon} className="img-fluid" />
          </div>
          <a href="#" className="ib-text" onClick={handleShowGuaranteesListing}>
            <h4 className="removeCapital">
              {local_Strings.PortfolioLiabilitiesOption2}
            </h4>
            <h5>
              {BidirectionalNumber(
                helper.ConvertToQfbNumberFormat(userPortfolio.totalGuarantees)
              )}
              {" " + currentContext.userSettings.currency}
            </h5>
          </a>
        </div>
      </div>

      <GuaranteesListing
        showGuaranteesListingModal={showGuaranteesListing}
        hideGuaranteesListingModal={handleCloseGuaranteesListing}
        showGuaranteesDetailsModal={(gurRef: string, balance: string) => {
          handleCloseGuaranteesListing();
          setshowGuaranteesDetails(true);
          setReferenceId({ gurRef, balance });
        }}
      />
      {params && !!params.gurRef && (
        <GuaranteesDetails
          showGuaranteesDetailsModal={showGuaranteesDetails}
          hideGuaranteesDetailsModal={handleCloseGuaranteesDetails}
          backGuaranteesListingModal={handleBackGuaranteesDetails}
          params={params}
        />
      )}
    </div>
  );
}

export default Guarantees;
