import React, { useContext } from "react";
import * as helper from "../../Helpers/helper";
import { PortfolioContext } from "../../pages/Homepage";
import { AuthContext } from "../../providers/AuthProvider";
import BidirectionalNumber from "../../shared/bidi-number";
import { localStrings as local_Strings } from "../../translations/localStrings";
import Cash from "./cash/Cash";
import Deposite from "./deposite/Deposite";
import Investments from "./investments/Investments";

function AssetsLanding() {
  const currentContext = useContext(AuthContext);
  const userPortfolio = useContext(PortfolioContext);

  return (
    <div className="box min-h-16">
      <h3 className="mt-1">{local_Strings.PortfolioAssetsTitle}</h3>
      <h2 className="mb-2">
        {BidirectionalNumber(
          helper.ConvertToQfbNumberFormat(userPortfolio.totalAssets)
        )}{" "}
        <small>{currentContext.userSettings.currency}</small>
      </h2>

      <div className="inner-boxes">
        <div className="row">
          <Cash></Cash>
          <Investments></Investments>
          <Deposite></Deposite>
        </div>
      </div>
    </div>
  );
}

export default AssetsLanding;
