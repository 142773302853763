import * as helper from "../Helpers/helper";

import React, { useContext, useState } from "react";

import { AuthContext } from "../providers/AuthProvider";
import BidirectionalNumber from "../shared/bidi-number";
import Constant from "../constants/defaultData";
import Highcharts from "highcharts/highstock";
import LoadingOverlay from "react-loading-overlay";
import PieChart from "highcharts-react-official";
import { PortfolioContext } from "../pages/Homepage";
import PuffLoader from "react-spinners/PuffLoader";
import { localStrings as local_Strings } from "../translations/localStrings";
import networthIcon from "../images/net-worth-icon.svg";

interface iTotalNetWorthDetails {
  showTotalNetWorthDetailsModal: boolean;
  hideTotalNetWorthDetailsModal: () => void;
}

const TotalNetWorthDetails = () => {
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);
  const userPortfolio = useContext(PortfolioContext);
  const [isLoading, setLoading] = useState(true);

  const chart_data = helper.prepareTotalNetWorth(
    userPortfolio,
    currentContext.language === "ar" ? true : false
  );

  const afterChartCreated = (chart) => {
    setLoading(false);
  };

  return userPortfolio && !!userPortfolio.networth ? (
    <div className="col-lg-12 popup-box">
      <div className="inner-box m-0 mb-3 py-3">
        <div className="d-flex align-items-center">
          <div className="ib-icon">
            <img src={networthIcon} className="img-fluid" />
          </div>
          <div className="ib-text  col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="removeCapital">
                  {local_Strings.PortfolioTotalNetWorth}
                </h4>
                <h5>
                  {BidirectionalNumber(userPortfolio.networth)}
                  {" " + currentContext.userSettings.currency}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto text-center">
        {userPortfolio && !!userPortfolio.customerCode && (
          <PieChart
            highcharts={Highcharts}
            options={chart_data}
            callback={afterChartCreated}
          />
        )}
      </div>
      <LoadingOverlay
        active={isLoading}
        spinner={
          <PuffLoader
            size={Constant.SpnnerSize}
            color={Constant.SpinnerColor}
          />
        }
      />
    </div>
  ) : null;
};

export default TotalNetWorthDetails;
