import * as yup from "yup";

import { AddToLogs, SendOTP } from "../services/cmsService";
import { AuthContext, User } from "../providers/AuthProvider";
import { Form, Formik } from "formik";
import {
  IsAccountLocked,
  checkUsernameAndPassword,
} from "../services/authenticationService";
import React, { useContext, useState } from "react";

import Constant from "../constants/defaultData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ForgotPasswordLanding from "./ForgotPassword/ForgotPasswordLanding";
import InvalidFieldError from "../shared/invalid-field-error";
import LoadingOverlay from "react-loading-overlay";
import PuffLoader from "react-spinners/PuffLoader";
import Swal from "sweetalert2";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { localStrings as local_Strings } from "../translations/localStrings";
import { useHistory } from "react-router-dom";

const eye = <FontAwesomeIcon icon={faEye} />;

interface IProps {
  setUserCredentials: any;
  showOTP: any;
}

const Login: React.FC<IProps> = (props) => {
  const history = useHistory();
  const currentContext = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const initialValues: User = { username: "", password: "", otp: "" };
  local_Strings.setLanguage(currentContext.language);
  const loginFormValidationSchema = yup.object({
    username: yup
      .string()
      .required(local_Strings.GeneralValidation)
      .matches(/^\w+$/, local_Strings.Login_ArabicNumberHint),
    password: yup.string().required(local_Strings.GeneralValidation),
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const submitLogin = async (values: User) => {
    setLoading(true);
    const isValidCredentials = await checkUsernameAndPassword(
      values.username,
      values.password
    );

    if (isValidCredentials.accessToken !== "") {
      SendOTP(values.username)
        .then((res) => {
          props.setUserCredentials({
            username: values.username,
            password: values.password,
            otp: "",
          });
          props.showOTP(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      AddToLogs("Login Failure", "Login Failure", values.username);
      IsAccountLocked(values.username).then((res) => {
        if (res === true) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: local_Strings.LoginWithCredentialsLockedErrorMessage,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: local_Strings.WelcomeScreenClose,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: local_Strings.LoginWithCredentialsErrorMessage,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: local_Strings.WelcomeScreenClose,
          });
        }
      });
      setLoading(false);
    }
  };

  return (
    <div className="col-lg-4 col-container">
      <div className="box login-container">
        <div className="box-header">
          <h3>{local_Strings.LoginWithCredentialsTitle}</h3>
        </div>
        <LoadingOverlay
          active={isLoading}
          spinner={
            <PuffLoader
              size={Constant.SpnnerSize}
              color={Constant.SpinnerColor}
            />
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={loginFormValidationSchema}
          onSubmit={(values) => submitLogin(values)}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
          }) => (
            <div className="box-body" id="loginBox">
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    {local_Strings.LoginWithCredentialsUserNameLabel}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="usernameField"
                    value={values.username || ""}
                    onChange={handleChange("username")}
                    onBlur={handleBlur("username")}
                    maxLength={50}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                  {touched.username &&
                    errors.username &&
                    InvalidFieldError(errors.username)}
                </div>
                <div className="form-group">
                  <label>
                    {local_Strings.LoginWithCredentialsPasswordLabel}
                  </label>
                  <div className="pass-wrapper">
                    <input
                      type={passwordShown ? "text" : "password"}
                      autoComplete="new-password"
                      className="form-control"
                      id="passwordField"
                      value={values.password || ""}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      maxLength={50}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                    <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                  </div>
                  {touched.password &&
                    errors.password &&
                    InvalidFieldError(errors.password)}
                </div>
              </Form>
              <ForgotPasswordLanding />
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={() => handleSubmit()}
                >
                  {local_Strings.LoginWithCredentialsLoginButton}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
