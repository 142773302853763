import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import AuthOffersLanding from "../AuthOffers/AuthOffersLanding";
import { GetRmDisplayName } from "../../services/cmsService";
import { GetUserLocalData } from "../../Helpers/authHelper";
import HoldingsLanding from "../HoldingsLanding";
import Inbox from "../Inbox/Inbox";
import { Navbar } from "react-bootstrap";
import Notfications from "../Notifications/Notifications";
import Requests from "../requests/Requests";
import Transactions from "../transactions/Transactions";
import { localStrings as local_Strings } from "../../translations/localStrings";
import usericon from "../../images/user-icon.svg";

interface IAdminNavigation {
  userType: {
    Customer: boolean;
    Admin: boolean;
    RM: boolean;
    Management: boolean;
  };
}

const AdminNavigation: React.FC<IAdminNavigation> = (props) => {
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);
  const [rmName, setRmName] = React.useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const [showCustomerLink, setShowCustomerLink] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchRmName = async () => {
      const userData = await GetUserLocalData();
      if (userData) {
        const res = await GetRmDisplayName(userData.customerId);

        if (isMounted) {
          setRmName(res);
          if (userData.customerId !== currentContext.selectedCIF) {
            setShowCustomerLink(true);
            setShowNotifications(true);
          }
        }
      }
    };

    const SetManagementBar = async () => {
      const userData = await GetUserLocalData();
      if (userData) {
        if (isMounted) {
          if (userData.customerId !== currentContext.selectedCIF) {
            setShowCustomerLink(true);
            setShowNotifications(true);
          }
        }
      }
    };

    if (!!currentContext.selectedCIF && props.userType.RM) {
      setShowNotifications(false);
      fetchRmName();
    } else if (props.userType.Admin) {
      setRmName(local_Strings.AdminLandingTitle);
      setShowNotifications(false);
    } else if (props.userType.Management) {
      setShowNotifications(false);
      SetManagementBar();
      setRmName(local_Strings.ManagementLandingTitle);
    }

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [currentContext.selectedCIF, currentContext.language, props.userType]);

  return (
    <div className="col-md-7">
      <div className="welcomeText text-right mt-3  d-flex justify-content-end align-items-center">
        <span>
          {props.userType.RM
            ? local_Strings.navigationUserMessage + rmName
            : rmName}
          <img className="mx-1" width="20" src={usericon} />
        </span>
        &nbsp;
        <a
          className="cursor-pointer  log-out"
          href="#"
          title="Exit"
          onClick={currentContext.logout}
        >
          <i className="fa fa-sign-out text-sm" />
        </a>
      </div>
      {showNotifications && (
        <Navbar expand="md" variant="dark">
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="fa fa-bars"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav">
              {showCustomerLink && (
                <React.Fragment>
                  <HoldingsLanding />
                  <Transactions isTransferAllowed={true} />
                  <AuthOffersLanding />
                  <Requests></Requests>
                  <li className="nav-item">
                    <Inbox />
                    <Notfications />
                  </li>
                </React.Fragment>
              )}
            </ul>
          </Navbar.Collapse>
        </Navbar>
      )}
    </div>
  );
};

export default AdminNavigation;
