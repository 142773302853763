import React, { useContext } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import Faqs from "../Faqs";
import SettingsAnchor from "../Settings/SettingsAnchor";
import { localStrings as local_Strings } from "../../translations/localStrings";
import { useHistory } from "react-router-dom";

function RMToolBarRight() {
  const history = useHistory();
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);

  return (
    <div className="col-md-9">
      <div className="topRight text-right">
        <SettingsAnchor></SettingsAnchor>
        <Faqs />
      </div>
    </div>
  );
}

export default RMToolBarRight;
