import * as helper from "../../../Helpers/helper";

import {
	GetCashListing,
	GetRelatedCashListing,
} from "../../../services/cmsService";
import {
	IAccountBalance,
	emptyAccountBalance,
} from "../../../Helpers/publicInterfaces";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import Constant from "../../../constants/defaultData";
import FilterMoreButtonControl from "../../../shared/FilterMoreButtonControl";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import NoResult from "../../../shared/NoResult";
import { PortfolioContext } from "../../../pages/Homepage";
import PuffLoader from "react-spinners/PuffLoader";
import cashIcon from "../../../images/cash-icon.svg";
import { localStrings as local_Strings } from "../../../translations/localStrings";
import xIcon from "../../../images/x-icon.svg";

interface iCashListing {
	showCashListingModal: boolean;
	hideCashListingModal: () => void;
	showCashDetailsModal: (selectedItem: IAccountBalance) => void;
}

function CashListing(props: iCashListing) {
	const currentContext = useContext(AuthContext);
	const userPortfolio = useContext(PortfolioContext);
	local_Strings.setLanguage(currentContext.language);
	const [isLoading, setLoading] = useState(false);
	const rowLimit: number = Constant.RecordPerPage;
	const [offset, setOffset] = useState<number>(rowLimit);
	const [data, setData] = useState<IAccountBalance[]>([emptyAccountBalance]);
	const relatedRowLimit: number = Constant.RecordPerPage;
	const [relatedOffset, setRelatedOffset] = useState<number>(relatedRowLimit);
	const [relatedData, setRelatedData] = useState<IAccountBalance[]>([
		emptyAccountBalance,
	]);

	useEffect(() => {
		let isMounted = true;

		const initialLoadMethod = async () => {
			setLoading(true);
			GetCashListing(currentContext.selectedCIF)
				.then((responseData: IAccountBalance[]) => {
					if (isMounted && responseData && responseData.length > 0) {
						setData(responseData);
						if (responseData.length < rowLimit) {
							setOffset(responseData.length);
						}
					}
				})
				.catch((e: any) => console.log(e))
				.finally(() => setLoading(false));

			GetRelatedCashListing(currentContext.selectedCIF)
				.then((responseData: IAccountBalance[]) => {
					console.log(responseData);
					if (isMounted && responseData && responseData.length > 0) {
						setRelatedData(responseData);
						if (responseData.length < relatedRowLimit) {
							setRelatedOffset(responseData.length);
						}
					} else {
						setRelatedData([]);
					}
				})
				.catch((e: any) => console.log(e))
				.finally(() => setLoading(false));
		};

		if (!!currentContext.selectedCIF) {
			initialLoadMethod();
		}

		return () => {
			isMounted = false;
		}; // use effect cleanup to set flag false, if unmounted
	}, [currentContext.selectedCIF, currentContext.language]);

	const renderItem = (
		item: IAccountBalance,
		index: number,
		isRelated: boolean
	) => (
		<li className='shown' key={index}>
			<a
				href='#'
				className='row align-items-center'
				onClick={() => props.showCashDetailsModal(item)}
			>
				<div className='col-1'>
					<span className='curr-icon'>
						{item.currency || currentContext.userSettings.currency}
					</span>
				</div>

				{isRelated && (
					<div className='col-4'>
						<h5 className='removeCapital'>{local_Strings.RelatedName + " "}</h5>
						<h4>{item.customerName || ""}</h4>
					</div>
				)}
				<div className={isRelated ? "col-3" : "col-5"}>
					<h5 className='removeCapital'>{local_Strings.AccountNo + " "}</h5>
					<h4>{item.accountNumber || ""}</h4>
				</div>
				<div className={isRelated ? "col-3" : "col-5"}>
					<h5 className='removeCapital'>
						{local_Strings.CashDetailsBalanceLabel}
					</h5>
					<h4>
						{BidirectionalNumber(
							helper.ConvertToQfbNumberFormatWithFraction(item.balance)
						)}
						{" " + item.currency}
					</h4>
				</div>
				<div className='col-1 text-right'>
					<i className='fa fa-chevron-right'></i>
				</div>
			</a>
		</li>
	);

	return (
		<div>
			<Modal
				show={props.showCashListingModal}
				onHide={props.hideCashListingModal}
				//size="lg"
				aria-labelledby='contained-modal-title-vcenter'
				centered
				scrollable
				dialogClassName='myModal'
			>
				<Modal.Header>
					<div className='d-flex align-items-center'>
						<div className='ib-icon'>
							<img src={cashIcon} className='img-fluid' />
						</div>
						<div className='ib-text'>
							<h4>{local_Strings.CashListingCash}</h4>
							{/* <h5>
                {BidirectionalNumber(userPortfolio.totalCash)}
                {" " + (currentContext.userSettings.currency || "")}
              </h5> */}
						</div>
					</div>
					<button
						type='button'
						className='close'
						onClick={props.hideCashListingModal}
					>
						<img src={xIcon} width='15' />
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className='col-lg-12 popup-box'>
						<div className='inner-box m-0 mb-3 py-3'>
							<div className='d-flex align-items-center'>
								<div className='ib-icon'>
									<img src={cashIcon} className='img-fluid' />
								</div>
								<div className='ib-text'>
									<h4 className='removeCapital'>
										{local_Strings.CashListingCash}
									</h4>
									<h5>
										{BidirectionalNumber(
											helper.ConvertToQfbNumberFormat(userPortfolio.totalCash)
										)}
										{" " + (currentContext.userSettings.currency || "")}
									</h5>
								</div>
							</div>
						</div>
					</div>

					<div className='box modal-box'>
						<ul className='box-list' id='dataList'>
							{data && data.length > 0 && !!data[0].accountNumber
								? data.map((item, index) => renderItem(item, index, false))
								: NoResult(local_Strings.CashListing_NoData)}
						</ul>
					</div>
					{/* <FilterMoreButtonControl
						showMore={data && data.length > rowLimit && offset < data.length}
						onClickMore={() => setOffset(offset + 5)}
					/> */}
					{relatedData && relatedData.length > 0 && (
						<div>
							<div className='col-lg-12 popup-box'>
								<div className='inner-box m-0 mb-3 py-3'>
									<div className='d-flex align-items-center'>
										<div className='ib-icon'>
											<img src={cashIcon} className='img-fluid' />
										</div>
										<div className='ib-text'>
											<h4 className='removeCapital'>
												{local_Strings.RelatedCashListing}
											</h4>
										</div>
									</div>
								</div>
							</div>
							<div className='box modal-box'>
								<ul className='box-list' id='dataList'>
									{relatedData &&
									relatedData.length > 0 &&
									!!relatedData[0].accountNumber
										? relatedData
												.slice(0, relatedOffset)
												.map((item, index) => renderItem(item, index, true))
										: NoResult(local_Strings.CashListing_NoData)}
								</ul>
							</div>
							<FilterMoreButtonControl
								showMore={
									relatedData &&
									relatedData.length > relatedRowLimit &&
									relatedOffset < relatedData.length
								}
								onClickMore={() => setRelatedOffset(relatedOffset + 5)}
							/>
						</div>
					)}
					<LoadingOverlay
						active={isLoading}
						spinner={
							<PuffLoader
								size={Constant.SpnnerSize}
								color={Constant.SpinnerColor}
							/>
						}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default CashListing;
