import {} from "react-bootstrap";

import * as yup from "yup";

import { DeletePasswordToken, SendOTP } from "../../services/cmsService";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import Constant from "../../constants/defaultData";
import { Formik } from "formik";
import InvalidFieldError from "../../shared/invalid-field-error";
import LoadingOverlay from "react-loading-overlay";
import PuffLoader from "react-spinners/PuffLoader";
import Swal from "sweetalert2";
import { localStrings as local_Strings } from "../../translations/localStrings";
import { resetPassword } from "../../services/authenticationService";

const queryString = require("query-string");

interface iResetPasswordStep2 {
  showResetPasswordStep2: boolean;
  hideResetPasswordStep2: () => void;
  showResetPasswordStep3: () => void;
  customerId: string;
  password?: string;
}

function ResetPasswordStep2(props: iResetPasswordStep2) {
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);
  const [isLoading, setLoading] = useState(false);
  const initialValues = {
    otp: "",
  };
  const queryStrings = queryString.parse(window.location.search);

  const registerFormStep3ValidationSchema = yup.object({
    otp: yup.number().required(local_Strings.GeneralValidation),
  });

  return props.showResetPasswordStep2 ? (
    <div className="box modal-box p-4">
      <LoadingOverlay
        active={isLoading}
        spinner={
          <PuffLoader
            size={Constant.SpnnerSize}
            color={Constant.SpinnerColor}
          />
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={registerFormStep3ValidationSchema}
        onSubmit={async (values) => {
          setLoading(true);

          console.log(props.customerId, props.password, values.otp);

          const res = await resetPassword(
            props.customerId,
            props.password,
            values.otp
          );

          if (res !== "" && res.result === true) {
            await DeletePasswordToken(queryStrings.token);
            Swal.fire({
              position: "center",
              icon: "success",
              title: local_Strings.PasswordResetTitle,
              html: local_Strings.PasswordResetSuccessMessage,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: local_Strings.WelcomeScreenClose,
            });
            props.hideResetPasswordStep2();
          } else {
            if (res !== "" && res.message === "WrongOTp") {
              Swal.fire({
                position: "center",
                icon: "error",
                title: local_Strings.otpErrorMessage,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: local_Strings.WelcomeScreenClose,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: local_Strings.otpErrorMessage,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: local_Strings.WelcomeScreenClose,
              });
            }
          }
          setLoading(false);

          // const res = await ValidateOTP(props.customerId, values.otp);
          // if (res) {
          //   props.showResetPasswordStep3();
          // } else {
          //   Swal.fire({
          //     position: "center",
          //     icon: "error",
          //     title: local_Strings.otpErrorMessage,
          //     showConfirmButton: false,
          //     showCancelButton: true,
          //     cancelButtonText: local_Strings.WelcomeScreenClose,
          //   });
          // }
          setLoading(false);
        }}
        enableReinitialize={true}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
          validateForm,
        }) => (
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-md-12 col-sm-12">
                <h5>{local_Strings.PasswordResetOTPHint}</h5>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-lg-12 form-group">
                <label>{local_Strings.PasswordResetOTPEnterOTP}</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.otp || ""}
                  onChange={handleChange("otp")}
                  onBlur={handleBlur("otp")}
                />
                {touched.otp && errors.otp && InvalidFieldError(errors.otp)}
                <div className="form-group text-right">
                  <a
                    href="#"
                    className="forgotLink"
                    onClick={async () => await SendOTP(props.customerId)}
                  >
                    {local_Strings.PasswordResetOTPResendOTP}
                  </a>
                </div>
              </div>
            </div>

            <div className="text-right">
              <button
                id="applyReqBtn"
                className="btn btn-primary"
                type="submit"
                onClick={(e) => {
                  validateForm(values);
                  if (isValid) {
                    handleSubmit();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: local_Strings.formValidationMessage,
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonText: local_Strings.WelcomeScreenClose,
                    });
                    touched.otp = true;
                  }
                }}
              >
                {local_Strings.PasswordResetButton}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  ) : null;
}

export default ResetPasswordStep2;
