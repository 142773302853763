import * as yup from "yup";

import {
  AddAcceptTermsStatusForCustomer,
  AddToLogs,
  IsCifAcceptedTerms,
  SendOTP,
} from "../services/cmsService";
import { AuthContext, User } from "../providers/AuthProvider";
import React, { useContext, useState } from "react";

import AuthTerms from "../components/Terms/AuthTerms";
import Constant from "../constants/defaultData";
import { Formik } from "formik";
import InvalidFieldError from "../shared/invalid-field-error";
import LoadingOverlay from "react-loading-overlay";
import PuffLoader from "react-spinners/PuffLoader";
import Swal from "sweetalert2";
import { getUserRole } from "../services/apiServices";
import { localStrings as local_Strings } from "../translations/localStrings";
import { useHistory } from "react-router-dom";

interface iPasswordResetOTP {
  otp: string;
}
interface IProps {
  userDetail: User;
}

const SubmitOTP: React.FC<IProps> = ({ userDetail }) => {
  const history = useHistory();
  const currentContext = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  local_Strings.setLanguage(currentContext.language);
  const initialValues: iPasswordResetOTP = {
    otp: "",
  };
  const loginFormValidationSchema = yup.object({
    otp: yup.string().required(local_Strings.GeneralValidation),
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showAuthTerms, setShowAuthTerms] = useState(false);

  const submitOTP = async (values: iPasswordResetOTP) => {
    setLoading(true);
    setShowErrorMessage(false);

    const loginResponse = await currentContext.login({
      ...userDetail,
      otp: values.otp,
    });
    if (loginResponse) {
      await AddToLogs("Login Success", "Login Success", userDetail.username);

      redirectUser();
    } else {
      setLoading(false);

      Swal.fire({
        position: "center",
        icon: "error",
        title: local_Strings.otpErrorMessage,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: local_Strings.WelcomeScreenClose,
      });
      setShowErrorMessage(true);
    }
  };

  const redirectUser = async () => {
    const role = await getUserRole(userDetail.username).finally(() => {
      setLoading(false);
    });
    if (role && role !== undefined) {
      if (role.name === Constant.Customer) {
        const res = await IsCifAcceptedTerms(userDetail.username);
        if (res) {
          history.push(`/${currentContext.language}/Home`);
        } else {
          setShowAuthTerms(true);
        }
      } else if (role.name === Constant.RM) {
        history.push(`/${currentContext.language}/RMLanding`);
      } else if (role.name === Constant.Management) {
        history.push(`/${currentContext.language}/Managment`);
      } else if (role.name === Constant.CMSADMIN) {
        currentContext.changeLanguage("en");
        history.push(`/en/Admin`);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: local_Strings.GenericErrorMessage,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: local_Strings.WelcomeScreenClose,
        });
      }
    }
  };

  return (
    <div className="col-lg-4 col-container">
      <div className="box login-container">
        <div className="box-header">
          <h3>{local_Strings.LoginWithCredentialsTitle}</h3>
        </div>
        <LoadingOverlay
          active={isLoading}
          spinner={
            <PuffLoader
              size={Constant.SpnnerSize}
              color={Constant.SpinnerColor}
            />
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={loginFormValidationSchema}
          onSubmit={(values) => submitOTP(values)}
        >
          {({
            values,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="box-body" id="OtpBox">
              <div className="form-group">
                <p>{local_Strings.PasswordResetOTPHint}</p>
              </div>
              <div className="form-group">
                <label>{local_Strings.PasswordResetOTPEnterOTP}</label>
                <input
                  type="text"
                  className="form-control"
                  pattern="[0-9]*"
                  maxLength={6}
                  value={values.otp || ""}
                  onChange={(e) => {
                    if (
                      e.currentTarget.validity.valid &&
                      e.currentTarget.value.length <= 6
                    ) {
                      setFieldValue("otp", e.target.value);
                    }
                  }}
                  onBlur={handleBlur("otp")}
                />
                {touched.otp && errors.otp && InvalidFieldError(errors.otp)}
                {showErrorMessage &&
                  InvalidFieldError(local_Strings.otpErrorMessage)}
              </div>
              <div className="form-group text-right">
                <a
                  href="#"
                  className="forgotLink"
                  onClick={async () => {
                    const optResult = await SendOTP(userDetail.username);
                    if (optResult) {
                      Swal.fire({
                        position: "center",
                        icon: "success",
                        title: local_Strings.OTPSentMessage,
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: local_Strings.WelcomeScreenClose,
                      });
                    } else {
                      Swal.fire({
                        position: "center",
                        icon: "error",
                        title: local_Strings.GenericErrorMessage,
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: local_Strings.WelcomeScreenClose,
                      });
                    }
                  }}
                >
                  {local_Strings.PasswordResetOTPResendOTP}
                </a>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={() => handleSubmit()}
                >
                  {local_Strings.PasswordResetOTPButton}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <AuthTerms
        showAuthTermsModal={showAuthTerms}
        hideAuthTermsModal={async () => {
          currentContext.logout();
          window.location.reload();
        }}
        showWelcomePage={async () => {
          await AddAcceptTermsStatusForCustomer(userDetail.username);
          setShowAuthTerms(false);
          history.push(`/${currentContext.language}/Home`);
        }}
        showButtons={true}
      />
    </div>
  );
};

export default SubmitOTP;
