import * as helper from "../../../Helpers/helper";

import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import DepositeDetails from "./DepositeDetails";
import DepositeListing from "./DepositeListing";
import DepositeRecievedProfit from "./DepositeRecievedProfit";
import { GetRelatedDepositeListing } from "../../../services/cmsService";
import { IDeposit } from "../../../Helpers/publicInterfaces";
import { PortfolioContext } from "../../../pages/Homepage";
import depositIcon from "../../../images/deposit-icon.svg";
import { localStrings as local_Strings } from "../../../translations/localStrings";

function Deposite() {
	const currentContext = useContext(AuthContext);
	const userPortfolio = useContext(PortfolioContext);
	const [depositItem, selectItemFromList] = useState<IDeposit>(null);

	const [showDepositeListing, setShowDepositeListing] = useState(false);
	const [showDepositeDetails, setshowDepositeDetails] = useState(false);
	const [showDepositeRecievedProfit, setShowDepositeRecievedProfit] =
		useState(false);

	const [hasRelated, setHasRelated] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const initialLoadMethod = async () => {
			GetRelatedDepositeListing(currentContext.selectedCIF)
				.then((responseData: IDeposit[]) => {
					if (isMounted && responseData && responseData.length > 0) {
						setHasRelated(true);
					}
				})
				.catch((e: any) => console.log(e))
				.finally(() => {});
		};

		if (!!currentContext.selectedCIF) {
			initialLoadMethod();
		}

		return () => {
			isMounted = false;
		}; // use effect cleanup to set flag false, if unmounted
	}, [currentContext.selectedCIF, currentContext.language]);

	return (
		<div className='col-lg-4'>
			<div className='inner-box'>
				<div className='d-flex align-items-center'>
					<div className='ib-icon'>
						<img src={depositIcon} className='img-fluid' />
					</div>
					<a
						href='#'
						className='ib-text'
						onClick={() => {
							if (
								(!!userPortfolio.totalDeposits &&
									parseInt(userPortfolio.totalDeposits) !== 0) ||
								hasRelated
							) {
								setShowDepositeListing(true);
							}
						}}
					>
						<h4 className='removeCapital'>
							{local_Strings.PortfolioAssetsOption3}
						</h4>
						<h5>
							{BidirectionalNumber(
								helper.ConvertToQfbNumberFormat(userPortfolio.totalDeposits)
							)}
							{" " + currentContext.userSettings.currency}
						</h5>
					</a>
				</div>
			</div>

			<DepositeListing
				showDepositeListingModal={showDepositeListing}
				hideDepositeListingModal={() => setShowDepositeListing(false)}
				showDepositeDetailsModal={(depositItem: IDeposit) => {
					setShowDepositeListing(false);
					setshowDepositeDetails(true);
					selectItemFromList(depositItem);
				}}
			/>
			{depositItem && !!depositItem && (
				<React.Fragment>
					<DepositeDetails
						showDepositeDetailsModal={showDepositeDetails}
						hideDepositeDetailsModal={() => setshowDepositeDetails(false)}
						backDepositeListingModal={() => {
							setshowDepositeDetails(false);
							setShowDepositeListing(true);
						}}
						showDepositeRecievedProfit={() => {
							setshowDepositeDetails(false);
							setShowDepositeRecievedProfit(true);
						}}
						depositItem={depositItem}
					/>
					<DepositeRecievedProfit
						showDepositeRecievedProfitModal={showDepositeRecievedProfit}
						hideDepositeRecievedProfitModal={() =>
							setShowDepositeRecievedProfit(false)
						}
						backDepositeRecievedProfitModal={() => {
							setShowDepositeRecievedProfit(false);
							setshowDepositeDetails(true);
						}}
						depositItem={depositItem}
					/>
				</React.Fragment>
			)}
		</div>
	);
}

export default Deposite;
