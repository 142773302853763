import * as helper from "../../../Helpers/helper";

import {
	IInvestment,
	IInvestmentDetail,
	emptyInvestmentDetail,
} from "../../../Helpers/publicInterfaces";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import Constant from "../../../constants/defaultData";
import { GetInvestmentsDetails } from "../../../services/cmsService";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import { localStrings as local_Strings } from "../../../translations/localStrings";
import xIcon from "../../../images/x-icon.svg";

interface iInvestmentsDetails {
	showInvestmentsDetailsModal: boolean;
	hideInvestmentsDetailsModal: () => void;
	backInvestmentsListingModal: () => void;
	showInvestmentsRecievedProfit: () => void;
	showInvestmentsBuyAndSell: () => void;
	investment: IInvestment;
}

function InvestmentsDetails(props: iInvestmentsDetails) {
	const currentContext = useContext(AuthContext);
	local_Strings.setLanguage(currentContext.language);
	const [isLoading, setLoading] = useState(false);
	const [item, setDetail] = useState<IInvestmentDetail>(emptyInvestmentDetail);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		GetInvestmentsDetails(
			props.investment.cif || "",
			props.investment.subAssetID
		)
			.then((responseData: any) => {
				if (responseData && responseData.length > 0 && isMounted) {
					const _detail = helper.transformingStringToJSON(
						responseData[0],
						currentContext.language
					);
					setDetail(_detail);
				}
			})
			.catch((e: any) => console.log(e))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		}; // use effect cleanup to set flag false, if unmounted
	}, [props.investment.subAssetID, currentContext.language]);

	return (
		<Modal
			show={props.showInvestmentsDetailsModal}
			onHide={props.hideInvestmentsDetailsModal}
			// size="lg"
			aria-labelledby='contained-modal-title-vcenter'
			centered
			scrollable
			dialogClassName='myModal'
		>
			<Modal.Header>
				<div className='d-flex align-items-center'>
					<div className='modal-header-text'>
						<a
							href='#'
							onClick={props.backInvestmentsListingModal}
							className='backToAccountsList'
						>
							<i className='fa fa-chevron-left'></i>
						</a>
					</div>
					<div className='ib-text'>
						<h4>{local_Strings.InvestmentsDetailsTitle}</h4>
					</div>
				</div>

				<button
					type='button'
					className='close'
					onClick={props.hideInvestmentsDetailsModal}
				>
					<img src={xIcon} width='15' />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className='box modal-box'>
					<LoadingOverlay
						active={isLoading}
						spinner={
							<PuffLoader
								size={Constant.SpnnerSize}
								color={Constant.SpinnerColor}
							/>
						}
					/>
					<ul className='box-list' id='investmentModalDetails'>
						<li className='py-3 px-4 mb-3 border-bottom rounded-0'>
							<div className='row align-items-center'>
								<div className='col-sm-8'>
									<h3 className='text-capitalize color-gold'>
										{local_Strings.Investment +
											" | " +
											props.investment.secDesciption}
									</h3>
									<h3 className='text-sm'>
										{BidirectionalNumber(
											item.InvestmentAmount ? item.InvestmentAmount.value : ""
										)}
									</h3>
								</div>
								<div className='col-sm-4 text-sm-right'>
									<strong className='status-badge status-badge-lg color-gold text-xs'>
										{props.investment.securityCCY || ""}
									</strong>
									<br />
									<strong className='status-badge status-badge-lg'>
										{props.investment.profitRate || ""}
									</strong>
								</div>
							</div>
						</li>
					</ul>
					{Object.keys(item).map((key, index) => (
						<div className='px-4' key={index}>
							{item[key] && (
								<div className='formGrp'>
									<label>{item[key].label}:</label>
									<p>{item[key].value || ""}</p>
								</div>
							)}
						</div>
					))}
					<div className='text-right px-4'>
						<button
							id='viewBuySellTransaction'
							className='text-capitalize btn btn-primary maxSizeBtn mx-1'
							onClick={props.showInvestmentsBuyAndSell}
						>
							{local_Strings.ViewBuySellTransactions}
						</button>
						<button
							id='viewReceivedTransaction'
							className='text-capitalize btn btn-primary maxSizeBtn mx-1'
							onClick={props.showInvestmentsRecievedProfit}
						>
							{local_Strings.ViewReceivedProfitTransactions}
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default InvestmentsDetails;
