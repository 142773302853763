const ar = {
	english: "EN",
	arabic: "AR",
	FooterItem1: "الرئيسية",
	FooterItem2: "أخبار",
	FooterItem3: "المنتجات",
	FooterItem4: "اتصل بنا",
	FooterItem5: "الأصول",
	FooterItem6: "المعاملات",
	FooterItem7: "الطلبات",
	FooterItem8: "عروض",
	FooterItem9: "محفظة العملاء",
	FooterItem10: "طلبات العميل",
	FooterItem11: "مناصب البنك",
	AnonymousTermsTitle: "الشروط و الأحكام",
	AnonymousTermsDesc:
		"تطبيق خدمات بنك  ليشا الإلكترونية هو تطبيق موجه فقط لاستخدام 'المستثمر المؤهل'. من خلال استعمال هذا التطبيق يقر العميل على مسؤوليته الخاصة بأنه يستوفي جميع معايير التأهل 'للمستثمر المؤهل' كما ورد تعريفه في هذه الأحكام والشروط، وفي قوانين مركز قطر للمال بصيغتها المعدلة من وقت لآخر.",

	AnonymousTermsHint1: " وقد تم تعريف المستثمر المؤهل كالتالي: ",
	AnonymousTermsHint2Bold: "المستثمر المؤهل:  ",
	AnonymousTermsHint2:
		"وتعود على الأشخاص الطبيعيين أو القانونيين الذين يستوفون المعايير المنصوص عليها في التعريف التالي، ويعد هؤلاء مؤهلون للاشتراك في خدمات ومنتجات بنك ليشا. ",
	AnonymousTermsEligibilityTitle: "معايير التأهل:",

	AnonymousTermsEligibilityOl1: "1.	شخص طبيعي: ",
	AnonymousTermsEligibilityOl1Ul1:
		"أن يبلغ صافي قيمة ثروته أربعة (4) مليون ريال قطري على الأقل ويستثنى من هذا المبلغ قيمة مسكن المستثمر المؤهل (مكان إقامته) الأساسي.",
	AnonymousTermsEligibilityOl2: "2.	شركة:",
	AnonymousTermsEligibilityOl2Ul1:
		"أن تكون الشركة قد (أو خلال السنتين الماضيتين قد) إما: ",
	AnonymousTermsEligibilityOl2Ul1Sub1:
		"أن يقدر رأسمالها السهمي أو صافي قيمة أصولها بثمانية عشر (18) مليون ريال قطري أو أكثر; ",
	AnonymousTermsEligibilityOl2Ul1Sub2:
		"أن يقدر رأسمالها السهمي أو صافي قيمة أصولها بثمانية عشر (18) مليون ريال قطري أو أكثر;",

	AnonymousTermsEligibilityOl2Ul2:
		"أن تمتلك الشركة المستثمرة شركة قابضة أو شركة تابعة لها تكون قد (أو خلال السنتين الماضيتين قد) إما: ",
	AnonymousTermsEligibilityOl2Ul2Sub1:
		"أن يقدر رأسمالها أو صافي قيمة أصولها بثمانية عشر (18) مليون ريال قطري على الأقل،  ",
	AnonymousTermsEligibilityOl2Ul2Sub2:
		"أن تقدر قيمة صافي حجم أعمالها السنوي بثلاثين (30) مليون ريال قطري أو أكثر. ",
	AnonymousTermsEligibilityOl2Ul3:
		"الشراكات والشركات الفردية غير المسجلة التي تكون قد (أو خلال السنتين السابقتين، قد) إما:",
	AnonymousTermsEligibilityOl2Ul3Sub1:
		"أن تقدر قيمة صافي أصولها بثمانية عشر (18) مليون ريال قطري أو أكثر (ولا يستثنى من هذه القيمة احتساب القروض التي ضخها أي من الشركاء في رأسمال الشراكات المحدودة) ",
	AnonymousTermsEligibilityOl2Ul3Sub2:
		"أن تكون قيمة صافي حجم الأعمال ثلاثين (30) مليون ريال قطري أو أكثر. ",

	AnonymousTermsEligibilityOl3: "3.	الطرف المقابل المؤهل: ",
	AnonymousTermsEligibilityOl3Ul1:
		"أن تكون شركة مرخصة من قبل مركز قطر للمال.  ",
	AnonymousTermsEligibilityOl3Ul2: "مؤسسة مالية مرخصة حسب الأصول.",
	AnonymousTermsEligibilityOl3Ul3: "مركز مقاصة مؤهل أو بورصة مؤهلة. ",
	AnonymousTermsEligibilityOl3Ul4:
		"حكومة أو وكالة حكومية أو بنك مركزي أو غيرها من الهيئات النقدية الوطنية في أي دولة. ",
	AnonymousTermsEligibilityOl3Ul5:
		"هيئة استثمارية تابعة للدولة، أو أي هيئة مسؤولة عن أو تتدخل في إدارة المديونية العامة.",
	AnonymousTermsEligibilityOl3Ul6:
		"منظمة إقليمية أو دولية يكون أعضاءها إما دول أو بنوك مركزية أو هيئات نقدية وطنية.",

	AnonymousTermsAccept: "أوافق",
	AnonymousTermsCancel: "إلغاء",

	AnonymousLandingTitle: "تسجيل الدخول بواسطة",
	AnonymousLandingTitleHint: "اختر خيار تسجيل الدخول الخاص بك",
	AnonymousLandingOption1: "بصمة الإصبع أو الوجه",
	AnonymousLandingOption2: "اسم المستخدم وكلمة المرور",
	AnonymousLandingOption3: "التسجيل لمستخدم جديد",
	AnonymousLandingInquiryText: "لأية استفسارات ، ",
	AnonymousLandingInquiryLink: "اتصل بنا",
	AnonymousLandingStockSectionTitle: " سعر سهم بنك ليشا",
	AnonymousLandingStockSectionStockPriceLabel: "السعر الحالي",
	AnonymousLandingStockSectionChangeLabel: "التغيير",
	AnonymousLandingStockSectionLastPriceLabel: "آخر سعر ",
	AnonymousLandingProductsAndOffers: "المنتجات والعروض",
	AnonymousLandingPublicNews: " آخر الأخبار",

	LoginWithCredentialsTitle: "تسجيل الدخول",
	LoginWithCredentialsHint:
		"تسجيل الدخول باستخدام اسم المستخدم وكلمة المرور الخاصة بك",
	LoginWithCredentialsUserNameLabel: "ادخل اسم المستخدم",
	LoginWithCredentialsPasswordLabel: "كلمة المرور",
	LoginWithCredentialsPasswordResetLabel: "هل نسيت كلمة المرور؟",
	LoginWithCredentialsLoginButton: "تسجيل الدخول",
	GeneralValidation: "يجب عليك تعيين قيمة لهذا الحقل",
	LoginWithCredentialsErrorMessage: "اسم المستخدم او كلمة المرور غير صحيحة",
	LoginWithCredentialsLockedErrorMessage:
		"اسم المستخدم معلق, برجاء الاتصال بالبنك",

	PasswordResetInvalidData: "المعلومات المدخلة غير صحيحة",
	PasswordResetTitle: "إعادة تعيين كلمة المرور",
	PasswordResetHint: "يرجى ملء الحقول أدناه لإعادة تعيين كلمة المرور الخاصة بك",
	PasswordResetUserNameLabel: "ادخل اسم المستخدم",
	PasswordResetMobileLabel:
		"رقم الهاتف المحمول (يحتوي علي كود الدولة, مثال 97412345678+)",
	PasswordResetEmailLabel: "أدخل البريد الإلكتروني",
	PasswordResetButton: "إرسال",

	PasswordResetOTPTitle: "إعادة تعيين كلمة المرور",
	PasswordResetOTPHint: "ستتلقى رسالة نصية قصيرة تحتوي على رمز المرور",
	PasswordResetOTPEnterOTP: "ادخل رمز المرور",
	PasswordResetOTPResendOTP: "إعادة إرسال رمز المرور؟",
	PasswordResetOTPButton: "تأكيد",

	PasswordResetRequestTitle: "إعادة تعيين كلمة المرور",
	PasswordResetRequestHint:
		"يرجى متابعة عملية إعادة تعيين كلمة المرور الخاصة بك",
	PasswordResetRequestEnterPassword: "أدخل كلمة مرور جديدة",
	PasswordResetRequestResendConfirmPassword: "تأكيد كلمة المرور",
	PasswordResetRequestButton: "تأكيد",

	SignUpStep1Title: "تسجيل",
	SignUpStep1Hint: "يرجى ملء الحقول أدناه للتسجيل",
	SignUpStep1AuthorizationLabel: "رمز التفويض",
	SignUpStep1CIFLabel: "رقم تعريف العميل",
	SignUpStep1EmailLabel: "البريد الإلكتروني المسجل لدى البنك",
	SignUpStep1Mobile:
		"الهاتف المحمول المسجل لدى البنك (يحتوي علي كود الدولة , مثال:97412345678+)",
	SignUpStep1Button: "التالي",
	SignUpStep1Pager: "الخطوة 1 من 3",

	SignUpStep1CIFRegisterBefore: "رقم تعريف العميل مسجل من قبل",

	SignUpStep2Title: "تسجيل",
	SignUpStep2Hint: "يرجى متابعة عملية التسجيل الخاصة بك",
	SignUpStep2EnterPassword: "أدخل كلمة المرور",
	SignUpStep2ConfirmPassword: "تأكيد كلمة المرور",
	SignUpStep2Button: "التالي",
	SignUpStep2Pager: "الخطوة 2 من 3",

	SignUpStep3Title: "تسجيل",
	SignUpStep3Hint: "ستتلقى رسالة نصية قصيرة تحتوي على رمز المرور",
	SignUpStep3EnterOTP: "ادخل رمز المرور",
	SignUpStep3ResendOTP: "إعادة إرسال رمز المرور؟",
	SignUpStep3Button: "تأكيد عملية التسجيل",
	SignUpStep3Pager: "الخطوة 3 من 3",

	WelcomeScreenMsg:
		"أهلا بك {0} في بنك ليشا ، نحن سعداء بخدمتك وكونك عميل ، ونأمل أن نلبي جميع احتياجاتك ..",
	WelcomeScreenTitle: "أهلا بك",
	WelcomeScreenPopUpTitle: "اطلب معاودة الاتصال بي",
	WelcomeScreenPopUpMessage: "تم إرسال طلبك",
	WelcomeScreenClose: "أغلق",
	WelcomeScreenCall: "اتصل",
	WelcomeScreenSMS: "رسالة",
	WelcomeScreenEmail: "البريد",
	WelcomeScreenCallMeBackLabel: "يرجى من مدير العلاقة التواصل معنا",
	WelcomeScreenRMNameLabel: "اسم مدير العلاقة",
	RmLandingScreenLabel: "مرحبا",
	WelcomeScreenRMPhoneLabel: "رقم المكتب",
	WelcomeScreenRMMobileLabel: "رقم الهاتف المحمول",
	WelcomeScreenRMEmailLabel: "البريد الإلكتروني",
	WelcomeScreenViewPorfolioLabel: "عرض محفظتك",

	PortfolioTitle: "محفظة",
	PortfolioAssetsTitle: "الأصول",
	PortfolioAssetsOption1: "النقد",
	PortfolioAssetsOption2: "الإستثمارات",
	PortfolioAssetsOption3: "الوديعة لأجل",
	PortfolioLiabilitiesTitle: "الإلتزامات",
	PortfolioLiabilitiesOption1: "التسهيلات",
	PortfolioLiabilitiesOption2: "الضمانات البنكية",
	// PortfolioBG: require("../../assets/images/Intersection2Ar.png"),
	PortfolioCurrencyType: "ريال",
	PortfolioTotalNetWorth: "إجمالي القيمة الصافية",
	DrawerContentHomeLabel: "الرئيسية",
	DrawerContentSwitchLangLabel: "English",

	landingPageInvaildLoginMessage: "اسم المستخدم او كلمه المرور غير صحيحة",

	CashListingTitle: "قائمة الحسابات النقدية",
	CashListingCash: "النقد",
	CashListingCurrency: "ريال قطري",
	CashListingAccountNumberLabel: "رقم الحساب | ",

	CashDetailsTitle: "تفاصيل النقدية",
	CashDetailsFilter: "تنقية",
	CashDetailsRemoveFilter: "إزالة التصفية",
	CashDetailsFilterDate: "تاريخ",
	CashDetailsFilterTransactionAmount: "المبلغ",
	CashDetailsFilterType: "النوع",
	CashDetailsFilterButton: "تطبيق",
	CashDetailsFilterSelect: "اختر",

	CashDetailsHint: "تظهر المعاملات أدناه بحد أقصى 6 أشهر من المعاملات",
	CashDetailsBalanceLabel: "الرصيد",
	CashDetailsDescriptionLabel: "الوصف",
	CashDetailsAmountLabel: "المبلغ",
	CurrentBalanceLabel: "الرصيد الحالي:",
	CashDetailsTransactionRefrence: "مرجع المعاملة",
	CashDetailsBeneficiaryName: "أسم المستفيد",
	CashDetailsBeneficiaryAccount: "حساب المستفيد",
	CashDetailsPaymentDetails: "بيانات الدفع",

	ScrollForMoreLabel: "اضغط هنا للمزيد",
	CaretIconRight: "caretleft",

	DepositeListingTitle: "قائمة حساب الإيداع",
	DepositeListingCash: "الودائع لأجل",
	DepositeListingCurrency: "ريال قطري",
	DepositeListingAccountNumberLabel: "رقم الإيداع | ",

	DepositeDetailsRecieved: "تلقي معاملات الربح",
	DepositeDetailsFilter: "تنقية",
	DepositeDetailsRemoveFilter: "إزالة التصفية",
	DepositeDetailsFilterDate: "تاريخ",
	DepositeDetailsFilterTransactionAmount: "المبلغ",
	DepositeDetailsFilterType: "النوع",
	DepositeDetailsFilterButton: "تطبيق",
	DepositeDetailsFilterSelect: "اختر",

	DepositeDetailsTitle: "تفاصيل الإيداع",
	DepositeDetailsFromLabel: "من",
	DepositeDetailsMaturityDateLabel: "تاريخ الاستحقاق",
	DepositeDetailsExpectedProfiteRate: "معدل الربح المتوقع",
	DepositeDetailsProfitDistribution: "مدة توزيع الأرباح",
	DepositeDetailsViewRecievedButton: "عرض معاملة الربح المستلمة",

	DepositeDetailsBalanceLabel: "الإيداع:",
	DepositeDetailsDescriptionLabel: "الوصف",
	DepositeDetailsAmountLabel: "المبلغ",
	DepositeDetailsCurrentBalanceLabel: "الرصيد الحالي:",
	DepositeDetailsTransactionRefrence: "مرجع المعاملة",
	DepositeDetailsBeneficiaryName: "أسم المستفيد",
	DepositeDetailsBeneficiaryAccount: "حساب المستفيد",
	DepositeDetailsPaymentDetails: "بيانات الدفع",

	InvestmentsListingTitle: "قائمة الإستثمارات",
	InvestmentsListingCash: "قائمة الإستثمارات",
	InvestmentsListingCurrency: "ريال قطري",
	InvestmentsListingAccountNumberLabel: "رقم الاستثمار | ",

	LoanListing: "قائمة التسهيلات",

	DepositDetail: "تفاصيل الإيداع",
	InvestmentDetail: "تفاصيل الإستثمار",
	LoanDetail: "تفاصيل التسهيلات",

	BuyAndSellTransactionsListing_NoData: "لم يتم العثور على معاملات",
	ReceivedProfitTransactionsListing_NoData: "لم يتم العثور على معاملات الربح",
	CashListing_NoData: "لا توجد بيانات للعرض",
	DepositDetail_NoData: "لا توجد بيانات للعرض",
	DepositListing_NoData: "لم يتم العثور على معاملات الإيداع",
	InvestmentListing_NoData: "لا توجد استثمارات",
	HistoricalPayments_NoData: " لم يتم العثور على دفعات سابقة",
	OutstandingPayments_NoData: "لم يتم العثور على مدفوعات معلقة",
	NoDataToShow: "لا توجد بيانات لعرضها",
	OfferList_NoData: "لا تتوافر بيانات",
	DDL_NoData: "لا يوجد",
	Beneficiary_NoData: "No Beneficiary Found",

	DepositNo: "رقم الإيداع | ",
	AccountNo: "رقم الحساب | ",
	InvestmentNo: "رقم الاستثمار | ",
	LoanNo: "رقم التسهيل | ",
	Amount: "المبلغ",
	Description: "الوصف",
	Cash: "السيولة النقدية",
	Deposit: "الوديعة",
	Investment: "الاستثمار",
	Loan: "قرض",
	BuyAndSellTransactions_Status_Buy: "شراء",
	BuyAndSellTransactions_Status_Sell: "بيع",
	CashDetails_Filter_Debit: "مدين",
	CashDetails_Filter_Credit: "دائن",
	HistoricalPayments_PastDueSettlement: "تسوية المتاخرات",
	HistoricalPayments_SettlemenOfPayment: "تسوية المدفوعات",
	OutstandingPayments_PastDueSettlement: "تسوية المتاخرات",
	OutstandingPayments_SettlemenOfPayment: "تسوية التسهيلات",

	DepositsAndReceivedProfit: "الودائع والأرباح المحصلة",
	ClickToViewTheAccumulatedProfitReceived:
		"إضغط لعرض الأرباح المتراكمة المحصلة",
	CumulativeProfitReceived: "الأرباح المتراكمة المحصلة",

	ReceivedProfitText: "حصل على ربح",
	ViewReceivedProfitTransactionsToDate: "الأرباح المستلمة",
	BuyAndSellText: "البيع والشراء",
	ViewBuySellTransactions: "معاملات البيع والشراء",
	HistoricalPaymentsText: " المدفوعات السابقه",
	ViewHistoricalPayments: "عرض المدفوعات السابقه",
	OutstandingPaymentsText: " المدفوعات المستحقة",
	ViewOutstandingPayments: "عرض المدفوعات المستحقة",
	BuyAndSellTransactionsText: "معاملات البيع والشراء",

	TransactionScreenFilter_None: "اختر",
	TransactionScreenFilter_LastWeek: "الاسبوع الماضي",
	TransactionScreenFilter_LastMonth: "الشهر الماضي",
	TransactionScreenFilter_Last3Months: "آخر 3 أشهر",
	TransactionScreenFilter_CustomDate: "تاريخ مخصص",
	TransactionScreenFilter_Apply: "تطبيق",
	TransactionScreenFilter_StartDate: "تاريخ البدء",
	TransactionScreenFilter_EndDate: "تاريخ الانتهاء",
	TransactionScreenFilter_Amount: "المبلغ",
	TransactionScreenFilter_EQ: "يساوي",
	TransactionScreenFilter_GTE: "أكبر من أو يساوي",
	TransactionScreenFilter_GT: "أكثر من",
	TransactionScreenFilter_LTE: "اقل او يساوي",
	TransactionScreenFilter_LT: "أقل من",

	BankGuarantee: "ضمان بنكي",
	GuaranteeNo: "رقم الضمان | ",
	BankGuaranteeListing: "قائمة الضمانات البنكية",
	BankGuaranteeDetails: "تفاصيل الضمان البنكي",

	ViewReceivedProfit: "الأرباح المستلمة",
	ViewReceivedProfitNew: "الأرباح المستلمة",
	ViewReceivedProfitTransactions: "الأرباح المستلمة",

	InvestmentsDetailsTitle: "تفاصيل الاستثمار",
	InvestmentsDetailsFromLabel: "من",
	InvestmentsDetailsLocationLabel: "الموقع",
	InvestmentsDetailsDateLabel: "تاريخ البدء",
	InvestmentsDetailsProfitRateLabel: "معدل الربح المتوقع",
	InvestmentsDetailsProfitDistributionLabel: "مدة توزيع الأرباح",
	InvestmentsDetailsRecievedProfit: "معاملات الأرباح المستلمة",
	InvestmentsDetailsBuyAndSell: "معاملات البيع والشراء",
	InvestmentsDetailsLocationSample: "الدوحة قطر",

	NewsListingTitle: "قائمة الأخبار العامة في بنك ليشا",
	NewsListingDateSample: "08-08-2020",
	NewsListingTitleSample:
		"بنك  ليشا يستحوذ على مقر شركة بي إس إن سبورتس فارسيتي براندز في تكساس ليشا رسيتي براندز في تكساس",
	NewsDetailsTitle: "تفاصيل الخبر",

	OffersListingTitle: "قائمة العروض",
	OffersDetailsTitle: "تفاصيل العروض",
	OffersDetailsButton: "طلب الإشتراك",
	OfferRequest: "طلب عرض",
	OfferSubscriptionAmountLabel: "مبلغ الاشتراك",
	OfferSelectCurrencyLabel: "عملة الاشتراك",
	OfferButton: "إرسال",

	RequestListingTitle: "قائمة الطلبات",
	RequestListingAddButton: "طلب جديد",
	RequestListingFilterButton: "تنقية",
	RequestListingSampleStatus: "مغلق",
	RequestListingRemoveFilterButton: "إزالة التصفية",
	RequestListingFilterDate: "تاريخ",
	RequestListingFilterStatus: "الحالة",
	RequestListingFilterType: "نوع الطلب",
	RequestListingFilterSubmitButton: "تطبيق",
	RequestListingFilterSelect: "اختر",

	RequestDetailsTitle: "تفاصيل الطلب",
	RequestFromLabel: "من",
	RequestToLabel: "إلى",
	RequestAuditorNameLabel: "اسم المراجع",
	RequestTypeLabel: "حدد نوع الطلب",
	RequestButton: "إرسل الطلب إلى مدير العلاقة",
	RequestSelectLabel: "اختر",
	RequestAttachmentsLabel: "المرفقات",
	RequestStatusLabel: "الحالة",
	NewRequestTitle: "طلب جديد",

	AddAttachments: "أضف مرفقك",
	RemoveAttachments: "إزالة المرفق",
	BrowseAttachments: "تصفح",

	RequestOTPTitle: " تأكيد الطلب",
	RequestOTPHint: "ستتلقى رسالة نصية قصيرة تحتوي على رقم رمز المرور",
	RequestOTPEnterOTP: "أدخل رمز المرور",
	RequestOTPResendOTP: "إعادة إرسال رمز المرور؟",
	RequestOTPButton: "تأكيد",

	TransactionsListingTitle: "قائمة المعاملات",
	TransactionsListingFilterButton: "تنقية",
	TransactionsListingBeneficiariesButton: "المستفيدون",
	TransactionsListingNewButton: "جديد",
	TransactionsListingTitleSample: "التحويل لأحمد محمد",
	TransactionsListingStatus: "ألغيت",
	TransactionsDetailsTitle: "تفاصيل المعاملات",
	NewTransactionTitle: "معاملة جديدة",
	TransactionRequestButton: "إرسال",

	TransactionFromAccountLabel: " التحويل من الحساب",
	TransactionToAccountLabel: "التحويل إلى الحساب",
	TransactionCurrencyLabel: " اختر العملة",
	TransactionAmountLabel: "المبلغ",
	TransactionBenficiaryLabel: "المستفيد",
	TransactionDateLabel: "تاريخ الطلب",
	TransactionDescriptionLabel: "الغرض من التحويل",
	TransactionTransferTypeLabel: "نوع التحويل",

	BeneficiariesListingTitle: "قائمة المستفيدون",
	BeneficiariesListingIDLabel: "هوية المستفيدين | ",
	BeneficiariesListingCountrySample: "قطر",

	NewBeneficiaryTitle: "إضافة مستفيد جديد",
	EditBeneficiaryTitle: "تعديل مستفيد",
	BeneficiaryTransferTypeLabel: "نوع المستفيد",
	BeneficiaryIDLabel: "هوية المستفيد",
	BeneficiaryNickNameLabel: "القب",
	BeneficiaryQFBAccountOrIBAN: "رقم حساب المستفيد في بنك ليشاأو الأيبان",
	BeneficiaryBankLabel: "بنك المستفيد",
	BeneficiarySwiftCodeLabel: "رمز الSWIFT للبنك المستفيد",
	BeneficiaryFullNameLabel: "الاسم الكامل للمستفيد",
	BeneficiaryAccountNumberLabel: "رقم حساب المستفيد",
	BeneficiaryStreetNumberLabel: "رقم شارع المستفيد",
	BeneficiaryCityLabel: "المدينة",
	BeneficiaryCountryLabel: "الدولة",
	BeneficiaryBankCityLabel: "المدينة ",
	BeneficiaryBankNameLabel: "اسم البنك ",
	BeneficiaryBankAddressLabel: "عنوان البنك ",
	BeneficiaryIntermediaryBankLabel: " اسم البنك الوسيط ",
	BeneficiaryForeignCurrencyLabel: "عملة المستفيد ",
	BeneficiaryRoutingNumberLabel: "رقم التوجيه / رمز التصنيف ، إن وجد",
	BeneficiaryIBANLabel: "أيبان المستفيد",
	BeneficiaryIBANInternationalLabel: "أيبان المستفيد (إن وجد)",
	BeneficiaryIntermediaryBankSwiftCodeLabel: "رمز السويفت للبنك الوسيط",

	BeneficiaryAddressLabel: "عنوان المستفيد ",
	BeneficiarySaveButton: "حفظ",
	BeneficiaryCancelButton: "إلغاء",
	BeneficiaryEditButton: "تعديل",
	BeneficiaryDeleteButton: "حذف",
	BeneficiariesDetailsTitle: "تفاصيل المستفيد",
	BeneficiaryInvalidIban: "رقم IBAN غير صحيح",
	BeneficiaryIDLabelInternational: "هوية المستفيد",

	DeleteConfirmationTitle: "رسالة تأكيد",
	DeleteConfirmationBody: "هل انت متأكد",

	DocumentsListingTitle: "قائمة المستندات",
	DocumentsDetailsTitle: "تفاصيل المستندات",
	DocumentsDetailsDownloadButton: "تحميل",
	DocumentTitleSample: "الوثيقة رقم واحد",
	LiveHoldingTab: "الأصول الحالية",
	ExitediveHoldingTab: "الأصول المنتهية",

	RMLandingBankPositionsLabel: "طلبات العملاء",
	RMLandingClientRequestDetailsLabel: "تفاصيل الطلب",

	RMLandingClientsPortofoliosLabel: "محفظة العملاء",
	RMLandingBankPositionsAnchorText: "عرض جميع الطلبات لاتخاذ إجراءات بشأنها",
	RMLandingClientsPortofoliosAnchorText: "عرض جميع بورتوفوليو لعملائك هنا",

	ConfirmationDone: "تم",
	RecievedProfit: "حصل علي ربح",

	SearchText: "بحث",
	RMClientsRequestListingTitle: "طلب قائمة العملاء",
	RMSampleAccount: "رقم تعريف مدير العلاقة | ",
	CustomerSampleAccount: "رقم تعريف العميل | ",
	RequestNumber: "رقم الطلب | ",
	ManagementSampleAccount: "الادارة | ",
	RMSampleTitle: "كشف حساب",
	RMIsRegisterAccount: "مسجل",
	RMIsNotRegisterAccount: "غير مسجل",

	RMClienPortfolioListingTitle: "قائمة محافظ العملاء",
	ClienPortfolioListingTitle: " محافظ العملاء",

	RMDetailsCIFLabel: "رقم تعريف العميل",
	RMDetailsNameLabel: "الاسم",
	RMDetailsMobileLabel: "رقم الهاتف المحمول",
	RMDetailsRequestDetailsLabel: "تفاصيل الطلب",
	RMDetailsChangeRequestStatusLabel: "تغيير حالة الطلب",
	RMDetailsbutton: "حفظ",

	InboxMessageListingTitle: "قائمة رسائل البريد الوارد",
	InboxMessageListingFilterWithLabel: "تصفية ب",
	InboxMessageListingAsOfLabel: "اعتبارا من:",

	InboxMessageDetailsTitle: "تفاصيل رسالة البريد الوارد",
	InboxMessgaesSampleDate: "الأربعاء 22 يوليو 2020",
	InboxMessageDetailsFromLabel: "من: ",
	InboxMessageDetailsToLabel: "إلى: ",
	InboxMessageDetailsPreviousLabel: "البيانات السابقة",

	ProductsAndOffersListingTitle: "قائمة المنتجات والعروض",
	ProductsAndOffersListingNumberLabel: "رقم المنتج |  ",
	ProductsAndOffersListingNameLabel: "اسم المنتج | ",
	ProductsAndOffersDetailsTitle: "تفاصيل المنتج والعروض",

	ContactUsTite: "اتصل بنا",
	ContactUsSubmitButton: "إرسال",
	ContactUsClearButton: "مسح",
	ContactUsCreatedDateLabel: "تاريخ الإضافة",

	ContactUsNameLabel: "الاسم",
	ContactUsCountryLabel: "البلد",
	ContactUsMobileLabel:
		"رقم الهاتف المحمول(يحتوي علي كود الدولة, مثال 97412345678+)",
	ContactUsEmailAddressLabel: "عنوان البريد الإلكتروني",
	ContactUsQueryLabel: "الموضوع",
	SignupSuccessTitle: "تم الإرسال",
	SignupSuccessMessage: "تهانيا. أنت الان مسجل في تطبيق بنك ليشا للجوال",

	RequestSuccessTitle: "تم الإرسال",
	RequestSuccessMessage: "تم استلام الطلب ؛ سيتم الاتصال بك في اقرب وقت",

	PasswordResetSuccessTitle: "تم الإرسال",
	PasswordResetSuccessMessage:
		"تم إعادة تعيين كلمة المرور الخاصة بك ، انقر هنا لتسجيل الدخول",

	GenericErrorMessage: "هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق",
	NewRequestSubmitRequiredMessage:
		"الرجاء إدخال جميع الحقول المطلوبة والتأكد من ملء حقل واحد على الأقل",
	NewRequestSubmitInvalidDate: "إلي تاريخ يجب أن يكون أكبر من من تاريخ",
	ManagementLandingTitle: "مرحبا الإدارة",
	ManagementLandingItem1Title: "محفظة البنك",
	ManagementLandingItem2Title: "محفظة العملاء ",
	ManagementLandingItem1Desc: "اضغط هنا لعرض صافي مركز البنك",
	ManagementLandingItem2Desc: "عرض جميع محافظ عملاء البنك هنا",

	SettingsLandingTitle: "إعدادات",
	SettingsLandingNameSample: "احمد حماد ناصر",
	SettingsLandingMobileSample: "+974 00000000",
	SettingsLandingEmailSample: "Ahmedhammad@gmail.com",

	SettingsLandingButton1: " تغيير العملة",
	SettingsLandingButton2: " تغيير كلمة المرور",
	SettingsLandingButton3: "تغيير كلمة المرور لمرة واحدة",
	SettingsLandingButton4: "تغيير اللغة",
	SettingSaveButton: "حفظ",
	SettingsCancelButton: "إلغاء",
	ChangeCurrencyTitle: "تغيير العملة",
	ChangeCurrencyLabelTitle: "عملة المحفظة",
	ChangeCurrencyQAR: "ريال قطري",
	ChangeCurrencyUSD: "دولار أمريكي",
	ChangeCurrencyGPB: "الجنيه الاسترليني",
	ChangeCurrencyEUR: "يورو",
	ChangeOTPTitle: "تغيير كلمة المرور لمرة واحدة",
	ChangeOTPLabel: "اختر طريقة ارسال رمز المرور الواحد",

	ChangeOTPSMS: "رسالة قصيرة",
	ChangeOTPEmail: "البريد الإلكتروني",
	ChangeOTPSMSAndEMail: "الرسائل القصيرة والبريد الإلكتروني",

	ChangeLanguageTitle: "تغيير اللغة",
	ChangeLanguageLabel: "اختر اللغة",
	ChangeLanguageArabic: "عربى",
	ChangeLanguageEnglish: "الإنجليزية",

	ChangePasswordTitle: "تغيير كلمة السر",
	ChangePasswordCurrentLabel: "كلمة المرور الحالية",
	ChangePasswordNewLabel: "كلمة مرور الجديدة",
	ChangePasswordConfirmLabel: "تأكيد كلمة المرور الجديدة",
	ConfirmationTitle: "نجاح",
	ConfirmationDesc: "تم حفظ عملك بنجاح",

	TransactionOTPTitle: "تأكيد العملية",
	SuccessMessageTitle: "نجاح العملية",
	InvalidOTP: "رمز المرور غير صحيح, من فضلك حاول مره اخرى",

	NotificationsListingTitle: "قائمة الإشعارات",
	NotificationsListingFilter: "نوع الإشعار",
	NotificationsDetailsTitle: " تفاصيل الإشعارات",
	NotificationFilter_All: "عرض الكل",

	ChangePassword_CurrentRequiredMsg: "كلمة المرور الحالية مطلوبة",
	ChangePassword_RequiredMsg: "كلمة المرور مطلوبة",
	ChangePassword_InvalidationMsg:
		"يجب أن تتكون كلمة المرور من 7 أحرف على الأقل وأن تتضمن رقمًا واحدًا على الأقل ورقمًا واحدًا وحرفًا كبيرًا وحرفاً مميزاً",
	ChangePassword_ConfirmRequiredMsg: "تأكيد كلمة المرور مطلوب",
	ChangePassword_MustMatchMsg: "الكلمة السريه يجب ان تتطابق",

	DrawerAnonymoudSignIn: "تسجيل الدخول",
	DrawerAnonymoudRegister: "التسجيل",
	DrawerAnonymoudNews: "آخر الأخبار",
	DrawerAnonymoudHome: "الرئيسية",
	DrawerAnonymoudeProductsAndOffers: "المنتجات والعروض",
	DrawerAnonymoudeContactUs: "اتصل بنا",
	DrawerAuthDocuments: "المستندات",
	DrawerAuthSignout: "تسجيل الخروج",
	DrawerAuthHome: "الرئيسية",
	DrawerAuthContactUS: "اتصل بنا",
	DrawerAuthTerms: "الشروط والأحكام",
	DrawerAuthInstructions: "تعليمات قياسية",
	DrawerAuthFaqs: "أسئلة وأجوبة",
	DrawerAuthSettings: "إعدادات",

	SelectItem: "اختر",
	FaqsTitle: "أسئلة وأجوبة",
	PositionAnalysisTitle: "صفحة  تحليل المحفظه",
	BankPositionsTitle: "صفحة محفظة البنك",
	ViewPositionAnalysisTitle: "عرض تحليل المحفظه",
	ManagementLandingAssetsTitle: "الأصول",
	ManagementLandingLiabilitiesTitle: "الإلتزامات",
	NotificationListingTitle: "إشعارات",
	RequestListingFilterStatusOption1: "بانتظار المراجعة",
	RequestListingFilterStatusOption2: "الطلبات المنفذة",
	RequestListingFilterStatusOption3: "قيد التنفيذ",
	RequestListingFilterStatusOption4: "مغلق",

	MessageFilter_Read: "مقروءة",
	MessageFilter_UnRead: "غير مقروءة",
	MessageFilter_All: "كل الرسائل",
	MyBeneficiariesAddNew: "اضافة مستفيد جديد",
	landingPageInvaildBiometric: "فشل التحقق من المعلومات الحيوية",
	selectPeriod: "حدد فترة",

	Last6Weeks: "آخر 6 أسابيع",
	Last6Months: "آخر 6 أشهر",
	Last6Quarters: "آخر 6 أرباع",
	Last3Years: "3 سنوات الماضية",
	CustomerInvestments: "استثمارات العملاء",
	FinancingBalancesandratesPBandHC: "أرصدة ومعدلات التمويل PB و HC",
	PastDuesPBandHC: "المستحقات السابقة PB و HC",
	CustomersDepositsandrates: "ودائع العملاء وأسعارها",
	BankCashBalances: "أرصدة البنك النقدية",
	MMFUNDBalances: "أرصدة MM FUND",
	SUKUKBalances: "أرصدة الصكوك",
	TreasuryPlacementsBalances: "أرصدة إيداعات الخزينة",
	TotalCash: "مجموع المبالغ النقدية",
	TotalInvestments: "إجمالي الإستثمارات",
	TotalDeposits: "إجمالي الودائع",
	TotalLoans: "إجمالي التسهيلات",
	BankGurantees: "الضمانات البنكية",
	Networth: "صافي القيمة",
	TotalAssets: "إجمالي الأصول",
	TotalLiabilities: "إجمالي المطلوبات",
	TotalBalances: "إجمالي الأرصدة",
	NetAverageRate: "متوسط ​​سعر الفائدة الصافي",

	BankPositionsTotalAssetSub1: "صكوك",
	BankPositionsTotalAssetSub2: "صندوق سوق المال",
	BankPositionsTotalAssetSub3: "ودائع في بنوك أخرى",
	BankPositionsTotalAssetSub4: "متأخرات العملاء",
	BankPositionsTotalAssetSub5: "الرصيد النقدي",
	BankPositionsTotalAssetSub6: "قروض العملاء",
	BankPositionsTotalAssetSub7: "الاستثمارات",

	BankPositionsTotalLiabilitiesSub1: "الاقتراض من البنوك",
	BankPositionsTotalLiabilitiesSub2: "نقد العملاء",
	BankPositionsTotalLiabilitiesSub3: "الودائع",

	otpErrorMessage: "رمز المرور غير صحيح, من فضلك حاول مره اخرى",

	StandardSettlementTitle: "تعليمات التسوية القياسية",
	StandardSettlementDesc:
		"ريم إيبسوم هو ببساطة نص شكلي في صناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في هذه الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع",
	ChangeLangConfirmationTitle: "رسالة تأكيد",
	ChangeLangConfirmationBody: "هل تريد تغيير اللغة الى الإنجليزية؟",
	Ok: "موافق",
	Cancel: "إلغاء",
	NoConnectionAlertTitle: "رسالة تنبيه",
	NoConnectionAlertBody: "الرجاء التحقق من اتصال الانترنت الخاص بك",
	Login_ArabicNumberHint:
		"من فضلك استخدم الارقام باللغة الانجليزية لتتمكن من تسجيل الدخول",
	SessionTimeOutMessage: "تم انتهاء وقت جلستك يرجى تسجيل الدخول مره أخرى",

	CorrespondentBank: "البنك المراسل",
	Location: "الموقع",
	SwiftCode: "سويفت كود",
	IBAN: "رقم آيبان ",
	NostroAccountNumber: "رقم حساب نوسترو",
	SettingChangePasswordError: "من فضلك ادخل البيانات الصحيحة",
	ManagemetSelectPeriod: "اختر فترة",
	ChartProfitRecieved: "الارباح",
	ChartDepositsAmount: "المبالغ",
	ProductsAndOffersAddNew: "اضافة جديد",
	ProductsAndOffersNameLabel: "الاسم ",
	ProductsAndOffersArNameLabel: "الاسم بالعربية",
	ProductsAndOffersExpireLabel: "تاريخ الإنتهاء",
	ProductsAndOffersDescrLabel: "الوصف ",
	ProductsAndOffersArDescrLabel: "الوصف بالعربية",
	ProductsAndOffersSaveButton: "حفظ",
	ProductsAndOffersDeleteButton: "حذف",
	ProductsAndOffersSavedMessage: "تم حفظ منتج جديد بنجاح",
	ProductsAndOffersDeletedMessage: "تم حذف المنتج بنجاح",

	NotificationsAddNew: "إضافة جديد",
	NotificationsDeleteButton: "حذف",
	NotificationsSubjectLabel: "الموضوع",
	NotificationsSavedMessage: "تم حفظ الإشعار بنجاح",

	deleteBtn: "حذف",
	deleteSure: "هل أنت واثق؟",
	deleteSureMessage: "هل أنت متاكد من حذف هذا العنصر؟",
	deleteYesBtn: "نعم ، احذفه!",
	cancelBtn: "إلغاء",
	NotificationsCustomerNameLabel: "العملاء",
	NotificationsNameLabel: "العنوان",
	NotificationsArNameLabel: "العنوان بالعربية",
	NotificationsExpireLabel: "تاريخ الإنتهاء",
	NotificationsDescrLabel: "تفاصيل الرسالة",
	NotificationsArDescrLabel: "تفاصيل الرسالة بالعربية",
	NotificationsSaveButton: "حفظ",

	OfferAddNew: "إضافة جديد",
	OfferNameLabel: "العنوان ",
	OfferArNameLabel: "العنوان بالعربية ",
	OfferExpireLabel: "تاريخ الإنتهاء",
	OfferDescrLabel: "الوصف",
	OfferArDescrLabel: "الوصف بالعربية",
	selectedOfferDetailsLabel: "تفاصيل العرض",
	selectedOfferDetailsArLabel: "تفاصيل العرض بالعربية",
	OfferAttachment: "المرفقات (PDF فقط)",
	OfferSaveButton: "حفظ",
	OfferDeleteButton: "حذف",

	OfferFileBrowseLabel: "تصفح لتحميل ملف",
	isEmptyText: " فارغ",
	moreThanLimit: "الحد الأقصى لحجم التحميل هو 2.5 ميغا بايت.",
	supportedFileTypeError: "الملف المحدد {*} غير مدعوم.",
	formValidationMessage: "يرجى ملء الحقول المطلوبة قبل الحفظ",
	sizeLabel: "الحجم",
	OfferSavedMessage: "تم حفظ عرض جديد بنجاح",
	OfferDeletedMessage: "تم حذف عرض بنجاح",
	searchPlaceholder: "بحث",
	/***Web** */

	footerTxt:
		"بنك ليشا مرخص من قبل هيئة تنظيم مركز قطر للمال بموجب ترخيص رقم 00091 ومدرج في خريطة موقع بورصة قطر |  ",
	footerTxtTerms: "الشروط و الاحكام",
	headerLogoTxt: "البوابة المصرفية الإلكترونية",

	registerLandingTitle: "غير مسجل حتى الآن؟",
	registerLandingInfo:
		"حقق أقصى استفادة من يومك من خلال الخدمات المصرفية عبر الإنترنت مع بنك ليشا.",
	registerLandingButton: "سجل الان",
	registerTitle: "سجل",
	registerStep1Hint: "يرجى تعبئة الحقول للتسجيل.",
	registerStep2Hint: "يرجى متابعة عملية التسجيل الخاصة بك.",
	registerStep3Hint: "يرجى تعبئة الحقول للتسجيل.",
	registerStep1StepCount: "الخطوة 1 من 3",
	registerStep2StepCount: "الخطوة2 من 3",
	registerStep3StepCount: "الخطوة 3 من 3",
	registerStep1Label1: "رمز التفويض",
	registerStep1Label2: "رقم تعريف العميل",
	registerStep1Label3: "البريد الإلكتروني المسجل لدى البنك",
	registerStep1Label4: "الهاتف المتحرك المسجل  لدى البنك",
	registerStep2Label1: "أدخل كلمة المرور",
	registerStep2Label2: "تأكيد كلمة المرور",
	registerStep3Label1: "أدخل رمز المرور",
	registerStep3Label2: "إعادة إرسال رمز المرور",

	registerStep1Button: "التالى",
	registerStep2Button: "التالى",
	registerStep3Button: "أكمل التسجيل",

	appLandingTitle: "يمكنك أيضًا الوصول إلى حسابك في أي وقت من هاتفك المحمول.",
	appLandingInfo: "حمل التطبيق اليوم",

	productAndOffersLandingTitle: "المنتجات والعروض",
	productAndOffersLandingInfo: "انقر هنا لمشاهدة أحدث ما لدينا!",
	productAndOffersLandingButton: "شاهد المزيد",
	productAndOffersListingLabel: "رقم المنتج",

	contactUsLandingTitle: "لأية استفسارات اتصل بنا",
	contactUsLandingInfo: "انقر هنا للاتصال بأي استفسار.",
	contactUsLandingButton: "اتصل",

	OfferSubscriptionsTite: "قوائم الاشتراكات",
	OfferSubscriptionsDateLabel: "تاريخ الاشتراكات",
	OfferSubscriptionsRequestLabel: "طلب الاشتراكات",
	OfferSubscriptionsOfferLabel: "اشترك في العرض ",
	OfferSubscriptionsAmountLabel: "كمية",
	OfferSubscriptionsCurrencyLabel: "عملة",

	pagingNext: "التالى",
	pagingPrevious: "السابق",

	navigationUserMessage: "مرحبا ",
	navigationItem1: "الأصول",
	navigationItem2: "المعاملات",
	navigationItem3: "العروض",
	navigationItem4: "الطلبات",
	navigationItem5: "الرسائل",
	navigationItem6: "إشعارات",

	topBarRightItem1: "تعليمات التسوية القياسية",
	topBarRightItem2: "قائمة الوثائق",
	topBarRightItem3: "إعدادات",
	topBarRightItem4: "اتصل بنا",

	landingInboxTitle: "صندوق الوارد",
	landingMore: "عرض المزيد",
	percentageLabel: "النسبة المئوية",
	exportToExcel: " تصدير إلى Excel ",
	documentName: "اسم المستند",
	documentNameAr: "اسم المستند بالعربية",
	documentPriority: "الأولوية",
	documentDate: "تاريخ المستند",
	documentShortDescription: "الوصف",
	documentShortDescriptionAr: "الوصف بالعربية",
	documentDeletedMessage: "تم حفظ مستند بنجاح",
	documentSavedMessage: "تم حذف مستند بنجاح",
	documentListingPriority: "أفضلية",
	DrillBackButtonText: "عودة",

	PortfolioNoInvestments: "لا استثمارات بعد",
	PortfolioNoDeposits: "لا توجد ودائع حتى الآن",
	PortfolioNoFacilities: "لم يتم العثور تسهيلات",

	OfferRequestSubmitMessage: 'تم إرسال طلب اشتراكك في العرض "[REPLACE ME]"',
	ContactUsSelectCountry: "حدد الدولة",
	OTPSentMessage: "تم إرسال كلمة المرور لمرة واحدة بنجاح ، يرجى التحقق.",
	fileDeleteConfirmMessage: "تريد حذف هذا الملف؟",

	NewBeneficiaryMsgTitle: "المستفيد مرسل",
	NewBeneficiaryMsgBody: "تم إرسال المستفيد الخاص بك بنجاح إلى RM الخاص بك",

	AccessDeniedMsgTitle: "تم الرفض",
	AccessDeniedMessage: "ليس لديك إذن للوصول إلى هذا القسم.",

	NoLiveHoldingsMesageDeposit: "لا توجد مقتنيات حية حتى الآن للإيداع",
	NoLiveHoldingsMesageInvestment: "لا توجد مقتنيات حية حتى الآن للاستثمار",
	NoClosedHoldingsMesageInvestment: "لا توجد ممتلكات مغلقة بعد للاستثمار",
	NoClosedHoldingsMesageDeposit: "لا توجد حيازات مغلقة للإيداع",

	BreadcrumbLandingTitle: "الرئيسية",
	BreadcrumbAdminTitle: "مشرف",

	ChartDepositProfitRecieved: "الودائع والأرباح المحصلة",
	ChartDrillDownHint: "إضغط لعرض الأرباح المتراكمة المحصلة",

	ContactUs_Mobile_Format_Validation_Message:
		"رقم الهاتف المحمول (يحتوي علي كود الدولة, مثال 97412345678+)",

	Signup_Autherization_Code: "رمز التفويض غير صحيح",
	Signup_CIF_Message: "رقم تعريف العميل غير صحيح",
	Signup_Email_Message: "البريد الإلكتروني غير صحيح",
	Signup_Mobile_Number_Message: "الهاتف المحمول غير صحيح",

	Transactions_Amount_Validation: "من فضلك ادخل ارقام فقط",
	BeneficiaryInvalidAccountOrIban:
		"رقم حساب في بنك ليشا أو الأيبان غير صحيح",
	BeneficiaryInvalidIbanNumber: "رقم حساب في بنك ليشا أو الأيبان غير صحيح",
	BeneficiaryInvalidSwiftCode: "رقم السويفت غير صحيح",

	NoDepositsHoldingsFound: "لم يتم العثور على ودائع",
	NoInvestmentsHoldingsFound: "لم يتم العثور على أي استثمارات",
	InvalidEmail: "من فضلك أدخل بريد أليكترونى صحيح",

	TransactionWithinSameAccount: "لا يسمح بالمعاملات داخل نفس الحساب",
	TransactionAmountLabel2: "المبلغ",
	RichTextImageUploadHint: "يجب أن تكون الصورة المرفقة أقل من 5 ميغا بايت",

	MobileNumberLengthError: "يجب ألا يقل رقم الهاتف المحمول عن 10 أحرف",

	SendButtonText: "Send",
	AdminLandingTitle: "مرحبًا ، مسؤول البوابة",
	AmountExceedBalance: "المبلغ أكبر من رصيدك",
	EarningTillDate: "الأرباح حتى تاريخه",
	MaxSizeAllowed: "الحجم الأقصي للملف المسموح بتحميله 2.5 ميجابايت",
	ContactUs_Name_Format_Validation_Message: "الاسم يجب أن يكون أحرف فقط",
	Preview: "عرض",
	Download: "تحميل",
	RelatedCashListing: "الحساب النقدي للعملاء ذات الصلة",
	RelatedDepoistListing: "ودائع العملاء ذات الصلة",
	RelatedInvestmentListing: "إستثمارات العملاء ذات الصلة",
	RelatedName: "الاسم",
};

export default ar;
