import React, { useContext, useState } from "react";
import * as helper from "../../../Helpers/helper";
import { ILoanItem } from "../../../Helpers/publicInterfaces";
import facilitiesIcon from "../../../images/facilities-icon.svg";
import { PortfolioContext } from "../../../pages/Homepage";
import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import { localStrings as local_Strings } from "../../../translations/localStrings";
import FacilitiesDetails from "./FacilitiesDetails";
import FacilitiesHistoricalPayment from "./FacilitiesHistoricalPayment";
import FacilitiesListing from "./FacilitiesListing";
import FacilitiesOutstandingPayment from "./FacilitiesOutstandingPayment";

function Facilities() {
  const currentContext = useContext(AuthContext);
  const userPortfolio = useContext(PortfolioContext);
  const [facilityItem, selectItemFromList] = useState<ILoanItem>(null);
  const [showFacilitiesListing, setShowFacilitiesListing] = useState(false);
  const [showFacilitiesDetails, setshowFacilitiesDetails] = useState(false);
  const [
    showFacilitiesOutstandingPayment,
    setShowFacilitiesOutstandingPayment,
  ] = useState(false);
  const [
    showFacilitiesHistoricalPayment,
    setShowFacilitiesHistoricalPayment,
  ] = useState(false);

  return (
    <div>
      <div className="inner-box">
        <div className="d-flex align-items-center">
          <div className="ib-icon">
            <img src={facilitiesIcon} className="img-fluid" />
          </div>
          <a
            href="#"
            className="ib-text"
            onClick={() => {
              if (
                !!userPortfolio.totalLoans &&
                parseInt(userPortfolio.totalLoans) !== 0
              ) {
                setShowFacilitiesListing(true);
              }
            }}
          >
            <h4 className="removeCapital">
              {local_Strings.PortfolioLiabilitiesOption1}
            </h4>
            <h5>
              {BidirectionalNumber(
                helper.ConvertToQfbNumberFormat(userPortfolio.totalLoans)
              )}
              {" " + currentContext.userSettings.currency}
            </h5>
          </a>
        </div>
      </div>

      <FacilitiesListing
        showFacilitiesListingModal={showFacilitiesListing}
        hideFacilitiesListingModal={() => setShowFacilitiesListing(false)}
        showFacilitiesDetailsModal={(selectedFacility: ILoanItem) => {
          setShowFacilitiesListing(false);
          setshowFacilitiesDetails(true);
          selectItemFromList(selectedFacility);
        }}
      />
      {facilityItem && !!facilityItem.ldReference && (
        <React.Fragment>
          <FacilitiesDetails
            showFacilitiesDetailsModal={showFacilitiesDetails}
            hideFacilitiesDetailsModal={() => setshowFacilitiesDetails(false)}
            backFacilitiesListingModal={() => {
              setshowFacilitiesDetails(false);
              setShowFacilitiesListing(true);
            }}
            showFacilitiesOutstandingPayment={() => {
              setshowFacilitiesDetails(false);
              setShowFacilitiesOutstandingPayment(true);
            }}
            showFacilitiesHistoricalPayment={() => {
              setshowFacilitiesDetails(false);
              setShowFacilitiesHistoricalPayment(true);
            }}
            facilityItem={facilityItem}
          />
          <FacilitiesOutstandingPayment
            showFacilitiesOutstandingPaymentModal={
              showFacilitiesOutstandingPayment
            }
            hideFacilitiesOutstandingPaymentModal={() =>
              setShowFacilitiesOutstandingPayment(false)
            }
            backFacilitiesOutstandingPaymentModal={() => {
              setShowFacilitiesOutstandingPayment(false);
              setshowFacilitiesDetails(true);
            }}
            facilityItem={facilityItem}
          />
          <FacilitiesHistoricalPayment
            showFacilitiesHistoricalPaymentModal={
              showFacilitiesHistoricalPayment
            }
            hideFacilitiesHistoricalPaymentModal={() =>
              setShowFacilitiesHistoricalPayment(false)
            }
            backFacilitiesHistoricalPaymentModal={() => {
              setShowFacilitiesHistoricalPayment(false);
              setshowFacilitiesDetails(true);
            }}
            facilityItem={facilityItem}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default Facilities;
