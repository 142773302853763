import { Link, useHistory } from "react-router-dom";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import ContactUsForm from "../ContactUs/ContactUsForm";
import Faqs from "../Faqs";
import SendPasswordResetLink from "../ResetPassword/SendPasswordResetLink";
import SettingsAnchor from "../Settings/SettingsAnchor";
import { localStrings as local_Strings } from "../../translations/localStrings";

function AdminToolBarRight() {
  const history = useHistory();
  const currentContext = useContext(AuthContext);
  local_Strings.setLanguage(currentContext.language);
  const [showContactUsForm, setShowContactUsForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

  return (
    <div className="col-md-9">
      <div className="topRight text-right">
        <Link to={`/${currentContext.language}/ContactUs`}>
          {local_Strings.topBarRightItem4}
        </Link>
        <a href="#" onClick={() => setShowResetPasswordForm(true)}>
          Send Reset Password
        </a>
        <Faqs />
        <SendPasswordResetLink
          showFormModal={showResetPasswordForm}
          hideFormModal={() => setShowResetPasswordForm(false)}
        />
      </div>
    </div>
  );
}

export default AdminToolBarRight;
