import * as helper from "../../../Helpers/helper";

import {
	IAccountBalance,
	ICommonFilter,
	ITransaction,
	emptyTransaction,
} from "../../../Helpers/publicInterfaces";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import Constant from "../../../constants/defaultData";
import FilterCommonControl2 from "../../../shared/FilterCommonControl2";
import { GetCashTransactions } from "../../../services/cmsService";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import ReactExport from "react-export-excel";
import TransactionListing from "../../../shared/TransactionListing";
import excelIcon from "../../../images/excel.svg";
import { localStrings as local_Strings } from "../../../translations/localStrings";
import xIcon from "../../../images/x-icon.svg";

interface iCashDetails {
	showCashDetailsModal: boolean;
	hideCashDetailsModal: () => void;
	backCashListingModal: () => void;
	cashItem: IAccountBalance;
}

function CashDetails(props: iCashDetails) {
	const currentContext = useContext(AuthContext);
	local_Strings.setLanguage(currentContext.language);
	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState<ITransaction[]>([emptyTransaction]);
	const [filteredData, setFilteredData] = useState<ITransaction[]>([
		emptyTransaction,
	]);
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	useEffect(() => {
		let isMounted = true;

		const initialLoadMethod = async () => {
			setLoading(true);
			GetCashTransactions(
				props.cashItem.customer || "",
				props.cashItem.accountNumber
			)
				.then((responseData: ITransaction[]) => {
					if (isMounted && responseData && responseData.length > 0) {
						// const _data = responseData.filter(
						//   (d) =>
						//     new Date(d.bookingDate) > moment().add(-3, "months").toDate()
						// );
						setData(responseData);
						setFilteredData(responseData);
					}
				})
				.catch((e: any) => console.log(e))
				.finally(() => setLoading(false));
		};

		if (!!currentContext.selectedCIF && props.showCashDetailsModal) {
			initialLoadMethod();
		} else {
			setData([emptyTransaction]);
			setFilteredData([emptyTransaction]);
		}

		return () => {
			isMounted = false;
		}; // use effect cleanup to set flag false, if unmounted
	}, [props.cashItem.accountNumber, props.showCashDetailsModal]);

	return (
		<Modal
			show={props.showCashDetailsModal}
			onHide={props.hideCashDetailsModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			scrollable
			dialogClassName='myModal'
		>
			<Modal.Header>
				<div className='modal-header-text'>
					<div className='row align-items-center'>
						<div className='col-2 col-sm-1 text-center'>
							<a
								href='#'
								onClick={props.backCashListingModal}
								className='backToAccountsList'
							>
								<i className='fa fa-chevron-left'></i>
							</a>
						</div>
						<div className='col-3 col-sm-3'>
							<h5>{local_Strings.AccountNo}</h5>
							<h4>{props.cashItem.accountNumber}</h4>
						</div>
						<div className='col-3 col-sm-3'>
							<h5>{local_Strings.CurrentBalanceLabel}</h5>
							<h4>
								{BidirectionalNumber(
									helper.ConvertToQfbNumberFormatWithFraction(
										props.cashItem.balance
									)
								)}
								{" " + props.cashItem.currency}
							</h4>
						</div>
						<div className='col-3 col-sm-3'>
							<h5>{local_Strings.CashDetailsHint}</h5>
						</div>
					</div>
				</div>
				<button
					type='button'
					className='close'
					onClick={props.hideCashDetailsModal}
				>
					<img src={xIcon} width='15' />
				</button>
			</Modal.Header>
			<Modal.Body>
				{data && data.length > 0 && !!data[0].bookingDate && (
					<FilterCommonControl2
						CheckBoxTitle={local_Strings.CashDetailsFilterType}
						CheckBoxLabels={[
							{ label: local_Strings.CashDetails_Filter_Debit, value: "Debit" },
							{
								label: local_Strings.CashDetails_Filter_Credit,
								value: "Credit",
							},
						]}
						clearFilter={() => {
							// const _data = data.filter(
							//   (d) =>
							//     new Date(d.bookingDate) > moment().add(-3, "months").toDate()
							// );
							setFilteredData(data);
						}}
						applyFilter={(filters: ICommonFilter) => {
							const _filteredData = helper.filterTransactions(data, filters);
							setFilteredData(_filteredData);
						}}
					/>
				)}

				<TransactionListing
					transactions={filteredData}
					showBalanceField={true}
					currency={props.cashItem.currency}
					NoDataMessage={local_Strings.CashListing_NoData}
				/>

				<LoadingOverlay
					active={isLoading}
					spinner={
						<PuffLoader
							size={Constant.SpnnerSize}
							color={Constant.SpinnerColor}
						/>
					}
				/>
				{filteredData &&
					filteredData.length > 0 &&
					!!filteredData[0].accountNumber && (
						<div className='exportExcel'>
							<ExcelFile
								filename={local_Strings.CashDetailsTitle}
								element={
									<a href='#'>
										<img src={excelIcon} className='img-fluid' />
										{local_Strings.exportToExcel}
									</a>
								}
							>
								<ExcelSheet
									data={filteredData}
									name={props.cashItem.accountNumber + " - Cash Details"}
								>
									<ExcelColumn
										label={local_Strings.AccountNo}
										value='accountNumber'
									/>
									<ExcelColumn
										label={local_Strings.RequestListingFilterDate}
										value='bookingDate'
									/>
									<ExcelColumn
										label={local_Strings.Amount}
										value='transaction_Amount'
									/>
									<ExcelColumn
										label={local_Strings.Description}
										value={"descriptions"}
									/>
									<ExcelColumn
										label={local_Strings.CashDetailsBalanceLabel}
										value={"balance"}
									/>
								</ExcelSheet>
							</ExcelFile>
						</div>
					)}
			</Modal.Body>
		</Modal>
	);
}

export default CashDetails;
