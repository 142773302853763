export default {
  IdentityBaseUrl: "https://identity.qfb.com.qa",
  ApiBaseUrl: "https://api.qfb.com.qa",
  PortalUrl: "https://ib.leshabank.com",
  LoginDetailsStorageKey: "storage_LoginUsernameAndPassword",
  UserProfileDetailsStorageKey: "storage_UserPofileDetails",
  RecordPerPage: 5,
  AccessTokenStorageKey: "storage_user_access_token",
  RefreshTokenStorageKey: "storage_user_refresh_token",
  SpinnerColor: "#B8E7F3",
  SpnnerSize: 140,
  Customer: "CUSTOMER",
  Management: "MANAGEMENT",
  RM: "RM",
  CMSADMIN: "CMSADMIN",
  AlertTimeout: 3000,
  MapApiKey: "AIzaSyChFfCU3MWxnnsh79D5Lf6_YYiEahZMvcQ",
};
