import * as helper from "../../../Helpers/helper";

import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import BidirectionalNumber from "../../../shared/bidi-number";
import { GetRelatedInvestmentsListing } from "../../../services/cmsService";
import { IInvestment } from "../../../Helpers/publicInterfaces";
import InvestmentsBuyAndSell from "./InvestmentsBuyAndSell";
import InvestmentsDetails from "./InvestmentsDetails";
import InvestmentsListing from "./InvestmentsListing";
import InvestmentsRecievedProfit from "./InvestmentsRecievedProfit";
import { PortfolioContext } from "../../../pages/Homepage";
import investIcon from "../../../images/invest-icon.svg";
import { localStrings as local_Strings } from "../../../translations/localStrings";

function Investments() {
	const currentContext = useContext(AuthContext);
	const userPortfolio = useContext(PortfolioContext);
	const [selectedInvestment, setInvestmentDetail] = useState<IInvestment>(null);

	const [showInvestmentsListing, setShowInvestmentsListing] = useState(false);
	const [showInvestmentsDetails, setshowInvestmentsDetails] = useState(false);
	const [showInvestmentsRecievedProfit, setShowInvestmentsRecievedProfit] =
		useState(false);
	const [showInvestmentsBuyAndSell, setShowInvestmentsBuyAndSell] =
		useState(false);

	const [hasRelated, setHasRelated] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const initialLoadMethod = async () => {
			GetRelatedInvestmentsListing(currentContext.selectedCIF)
				.then((responseData: IInvestment[]) => {
					if (isMounted && responseData && responseData.length > 0) {
						setHasRelated(true);
					}
				})
				.catch((e: any) => console.log(e))
				.finally(() => {});
		};

		if (!!currentContext.selectedCIF) {
			initialLoadMethod();
		}

		return () => {
			isMounted = false;
		}; // use effect cleanup to set flag false, if unmounted
	}, [currentContext.selectedCIF, currentContext.language]);

	return (
		<div className='col-lg-4'>
			<div className='inner-box'>
				<div className='d-flex align-items-center'>
					<div className='ib-icon'>
						<img src={investIcon} className='img-fluid' />
					</div>
					<a
						href='#'
						className='ib-text'
						onClick={() => {
							if (
								(!!userPortfolio.totalInvestment &&
									parseInt(userPortfolio.totalInvestment) !== 0) ||
								hasRelated
							) {
								setShowInvestmentsListing(true);
							}
						}}
					>
						<h4 className='removeCapital'>
							{local_Strings.PortfolioAssetsOption2}
						</h4>
						<h5>
							{BidirectionalNumber(
								helper.ConvertToQfbNumberFormat(userPortfolio.totalInvestment)
							)}
							{" " + currentContext.userSettings.currency}
						</h5>
					</a>
				</div>
			</div>
			<InvestmentsListing
				showInvestmentsListingModal={showInvestmentsListing}
				hideInvestmentsListingModal={() => setShowInvestmentsListing(false)}
				showInvestmentsDetailsModal={(item: IInvestment) => {
					setShowInvestmentsListing(false);
					setshowInvestmentsDetails(true);
					setInvestmentDetail(item);
				}}
			/>
			{selectedInvestment && !!selectedInvestment.subAssetID && (
				<React.Fragment>
					<InvestmentsDetails
						showInvestmentsDetailsModal={showInvestmentsDetails}
						hideInvestmentsDetailsModal={() => {
							setshowInvestmentsDetails(false);
							// setShowInvestmentsBuyAndSell(true);
						}}
						backInvestmentsListingModal={() => {
							setshowInvestmentsDetails(false);
							setShowInvestmentsListing(true);
						}}
						showInvestmentsRecievedProfit={() => {
							setshowInvestmentsDetails(false);
							setShowInvestmentsRecievedProfit(true);
						}}
						showInvestmentsBuyAndSell={() => {
							setshowInvestmentsDetails(false);
							setShowInvestmentsBuyAndSell(true);
						}}
						investment={selectedInvestment}
					/>
					<InvestmentsRecievedProfit
						showInvestmentsRecievedProfitModal={showInvestmentsRecievedProfit}
						hideInvestmentsRecievedProfitModal={() =>
							setShowInvestmentsRecievedProfit(false)
						}
						backInvestmentsRecievedProfitModal={() => {
							setShowInvestmentsRecievedProfit(false);
							setshowInvestmentsDetails(true);
						}}
						investment={selectedInvestment}
					/>
					<InvestmentsBuyAndSell
						showInvestmentsBuyAndSellModal={showInvestmentsBuyAndSell}
						hideInvestmentsBuyAndSellModal={() =>
							setShowInvestmentsBuyAndSell(false)
						}
						backInvestmentsBuyAndSellModal={() => {
							setShowInvestmentsBuyAndSell(false);
							setshowInvestmentsDetails(true);
						}}
						investment={selectedInvestment}
					/>
				</React.Fragment>
			)}
		</div>
	);
}

export default Investments;
