const en = {
	english: "EN",
	arabic: "AR",
	FooterItem1: "Home",
	FooterItem2: "News",
	FooterItem3: "Products",
	FooterItem4: "Contact Us",
	FooterItem5: "Holdings",
	FooterItem6: "Transfer",
	FooterItem7: "Requests",
	FooterItem8: "Offers",
	FooterItem9: "Clients Portfolio ",
	FooterItem10: "Client's Requests",
	FooterItem11: "Bank Positions",

	AnonymousTermsTitle: "Terms And Conditions",
	AnonymousTermsDesc:
		"Lesha Digital Banking Application is only intended for “Qualified Investors”. By using this Application, you acknowledge at your own risk that you fulfill the eligibility criteria for Qualified Investors as defined under these Terms and Conditions and as set out under the QFC Regulations as amended from time to time.",

	AnonymousTermsHint1: "The “Qualified Investor” was defined as follows:",
	AnonymousTermsHint2Bold: "Qualified Investor: ",
	AnonymousTermsHint2:
		"Refers to the natural persons and entities that meet the requirements stated in the definition above who shall be considered eligible for to subscribe for the Lesha products and services.",
	AnonymousTermsEligibilityTitle: "Eligibility Criteria:",

	AnonymousTermsEligibilityOl1: "1. Natural Persons:",
	AnonymousTermsEligibilityOl1Ul1:
		"Shall have a minimum net worth of four (4) million QAR, excluding the calculation of the value of the primary residence of the Qualified Investor. ",
	AnonymousTermsEligibilityOl2: "2. Corporate Entities:",
	AnonymousTermsEligibilityOl2Ul1:
		"A body corporate that has (or, at any time during the previous 2 years, has had) either: ",
	AnonymousTermsEligibilityOl2Ul1Sub1:
		"Called-up share capital, or net assets, of QR 18 million or more; ",
	AnonymousTermsEligibilityOl2Ul1Sub2:
		"Annual net turnover of QR 30 million or more;",

	AnonymousTermsEligibilityOl2Ul2:
		"A body corporate that has a holding company or subsidiary that has (or, at any time during the previous 2 years, has had) either:",
	AnonymousTermsEligibilityOl2Ul2Sub1:
		"called-up share capital, or net assets, of at least QR 18 million; ",
	AnonymousTermsEligibilityOl2Ul2Sub2:
		"Annual net turnover of QR 30 million or more;",
	AnonymousTermsEligibilityOl2Ul3:
		"A partnership or unincorporated association that has (or, at any time during the previous 2 years, has had) either:",
	AnonymousTermsEligibilityOl2Ul3Sub1:
		"Net assets of QR 18 million or more (calculated, in the case of a limited partnership, without deducting loans owing to any of the partners); ",
	AnonymousTermsEligibilityOl2Ul3Sub2:
		"Annual net turnover of QR 30 million or more.",

	AnonymousTermsEligibilityOl3: "3. Eligible Counterparty:",
	AnonymousTermsEligibilityOl3Ul1: "An authorized firm; ",
	AnonymousTermsEligibilityOl3Ul2: "A regulated financial institution; ",
	AnonymousTermsEligibilityOl3Ul3:
		"An eligible clearing house or eligible exchange; ",
	AnonymousTermsEligibilityOl3Ul4:
		"A government, government agency, or central bank or other national monetary authority, of any jurisdiction; ",
	AnonymousTermsEligibilityOl3Ul5:
		"A state investment body, or a body charged with, or intervening in, the management of the public debt; ",
	AnonymousTermsEligibilityOl3Ul6:
		"A supranational organization, the members of which are jurisdictions, central banks or national monetary authorities.",

	AnonymousTermsAccept: "I Accept",
	AnonymousTermsCancel: "CANCEL",

	AnonymousLandingTitle: "Login By",
	AnonymousLandingTitleHint: "Choose your Login option",
	AnonymousLandingOption1: "Fingerprint or Face ID",
	AnonymousLandingOption2: "Username and Password",
	AnonymousLandingOption3: "Register",
	AnonymousLandingInquiryText: "For any inquiries, ",
	AnonymousLandingInquiryLink: "Contact us",
	AnonymousLandingStockSectionTitle: " Lesha BankStock Price",
	AnonymousLandingStockSectionStockPriceLabel: "Current Price",
	AnonymousLandingStockSectionChangeLabel: "Change",
	AnonymousLandingStockSectionLastPriceLabel: "Previous Close",
	AnonymousLandingProductsAndOffers: "Products and Offers ",
	AnonymousLandingPublicNews: "Latest News",

	LoginWithCredentialsTitle: "Login",
	LoginWithCredentialsHint: "Login using your username and password",
	LoginWithCredentialsUserNameLabel: "Enter Username",
	LoginWithCredentialsPasswordLabel: "Password",
	LoginWithCredentialsPasswordResetLabel: "Forgot Password ?",
	LoginWithCredentialsLoginButton: "Login",
	GeneralValidation: "You must set a value for this field",
	LoginWithCredentialsErrorMessage: "Incorrect username or password.",
	LoginWithCredentialsLockedErrorMessage:
		"Your username is locked, please contact Lesha",

	PasswordResetTitle: "Password Reset",
	PasswordResetHint: "Kindly fill below fields to reset your password",
	PasswordResetUserNameLabel: "Enter Username",
	PasswordResetMobileLabel:
		"Enter Mobile (Include country code, ex: +97412345678)",
	PasswordResetEmailLabel: "Enter Email",
	PasswordResetButton: "Submit",
	PasswordResetInvalidData: "Please enter valid data",

	PasswordResetOTPTitle: "Password Reset",
	PasswordResetOTPHint: "You'll receive an SMS with the OTP number",
	PasswordResetOTPEnterOTP: "Enter OTP",
	PasswordResetOTPResendOTP: "Resend OTP?",
	PasswordResetOTPButton: "Confirm",

	PasswordResetRequestTitle: "Password Reset",
	PasswordResetRequestHint: "Kindly proceed with your password reset process",
	PasswordResetRequestEnterPassword: "Enter New Password",
	PasswordResetRequestResendConfirmPassword: "Confirm Password",
	PasswordResetRequestButton: "Confirm",

	SignUpStep1Title: "Register",
	SignUpStep1Hint: "Kindly fill below fields to Register",
	SignUpStep1AuthorizationLabel: "Lesha one time authorization code",
	SignUpStep1CIFLabel: "Customer Identification Number",
	SignUpStep1EmailLabel: "Lesha Registered Email",
	SignUpStep1Mobile:
		"Lesha Registered Mobile (Include country code, ex: +97412345678)",
	SignUpStep1Button: "Next",
	SignUpStep1Pager: "Step 1 of 3",

	SignUpStep1CIFRegisterBefore:
		"Customer Identification Number already register",

	SignUpStep2Title: "Register",
	SignUpStep2Hint: "Kindly proceed your registration process",
	SignUpStep2EnterPassword: "Enter Password",
	SignUpStep2ConfirmPassword: "Confirm Password",
	SignUpStep2Button: "Next",
	SignUpStep2Pager: "Step 2 of 3",

	SignUpStep3Title: "Register",
	SignUpStep3Hint: "You'll receive an sms with the OTP number",
	SignUpStep3EnterOTP: "Enter OTP",
	SignUpStep3ResendOTP: "Resend OTP ?",
	SignUpStep3Button: "Complete signup",
	SignUpStep3Pager: "Step 3 of 3",

	otpErrorMessage: "Incorrect OTP",

	DrawerContentHomeLabel: "Home",
	DrawerContentSwitchLangLabel: "العربية",
	WelcomeScreenTitle: "Relation Manager",
	WelcomeScreenMsg:
		"Welcome {0} to Lesha Bank, we are glad to serve you and have you as a client, we hope to satisfy all your financial needs..",
	WelcomeScreenPopUpTitle: "CALL ME BACK REQUSET",
	WelcomeScreenPopUpMessage: "Your request has been sent.",
	WelcomeScreenClose: "Close",
	WelcomeScreenCall: "Call",
	WelcomeScreenSMS: "SMS",
	WelcomeScreenEmail: "Email",
	WelcomeScreenCallMeBackLabel: "CALL ME BACK",
	WelcomeScreenRMNameLabel: "RM Name",
	WelcomeScreenRMPhoneLabel: "Phone Number",
	WelcomeScreenRMMobileLabel: "Mobile Number",
	WelcomeScreenRMEmailLabel: "Email",
	WelcomeScreenViewPorfolioLabel: "View Your Portfolio",

	PortfolioTitle: "Portfolio",
	PortfolioAssetsTitle: "Assets",
	PortfolioAssetsOption1: "Cash",
	PortfolioAssetsOption2: "Investments",
	PortfolioAssetsOption3: "Deposit",
	PortfolioLiabilitiesTitle: "Liabilities",
	PortfolioLiabilitiesOption1: "Facilities",
	PortfolioLiabilitiesOption2: "Guarantees",
	PortfolioCurrencyType: "QAR",
	PortfolioTotalNetWorth: "Net Position",

	landingPageInvaildLoginMessage: "Invalid Username or Password.",

	CashListingTitle: "Cash Account Listing",
	CashListingCash: "Cash",
	CashListingCurrency: "QAR",
	CashListingAccountNumberLabel: "Account No | ",

	CashDetailsTitle: "Cash Details",
	CashDetailsFilter: "Apply",
	CashDetailsRemoveFilter: "Remove Filters",
	CashDetailsFilterDate: "Date",
	CashDetailsFilterTransactionAmount: "Amount",
	CashDetailsFilterType: "Type",
	CashDetailsFilterButton: "Apply",
	CashDetailsFilterSelect: "Select",

	CashDetailsHint:
		"The below transactions show a maximum of 6 months transactions",
	CashDetailsBalanceLabel: "Balance",
	CashDetailsDescriptionLabel: "Description",
	CashDetailsAmountLabel: "Amount",
	CurrentBalanceLabel: "Current Balance ",
	CashDetailsTransactionRefrence: "Transaction Reference",
	CashDetailsBeneficiaryName: "Beneficiary Name",
	CashDetailsBeneficiaryAccount: "Beneficiary Account",
	CashDetailsPaymentDetails: "Payment Details",

	ScrollForMoreLabel: "Click for more",
	CaretIconRight: "caretright",

	DepositeListingTitle: "Deposit Listing",
	DepositeListingCash: "Deposit",
	DepositeListingCurrency: "QAR",
	DepositeListingAccountNumberLabel: "Deposit No | ",

	DepositeDetailsRecieved: "Received Profit Transaction",
	DepositeDetailsFilter: "Filter",
	DepositeDetailsRemoveFilter: "Remove Filters",
	DepositeDetailsFilterDate: "Date",
	DepositeDetailsFilterTransactionAmount: "Amount",
	DepositeDetailsFilterType: "Type",
	DepositeDetailsFilterButton: "Apply",
	DepositeDetailsFilterSelect: "Select",

	DepositeDetailsTitle: "Deposit Details",
	DepositeDetailsFromLabel: "From",
	DepositeDetailsMaturityDateLabel: "Maturity Date",
	DepositeDetailsExpectedProfiteRate: "Expected Profite Rate",
	DepositeDetailsProfitDistribution: "Profit Distribution Term",
	DepositeDetailsViewRecievedButton: "View Recieved Profit Transaction",

	DepositeDetailsBalanceLabel: "Deposit:",
	DepositeDetailsDescriptionLabel: "Description",
	DepositeDetailsAmountLabel: "Amount",
	DepositeDetailsCurrentBalanceLabel: "Current Balance: ",
	DepositeDetailsTransactionRefrence: "Transaction Reference",
	DepositeDetailsBeneficiaryName: "Beneficiary Name",
	DepositeDetailsBeneficiaryAccount: "Beneficiary Account",
	DepositeDetailsPaymentDetails: "Payment Details",

	InvestmentsListingTitle: "Investments Listing",
	InvestmentsListingCash: "Investments",
	InvestmentsListingCurrency: "QAR",
	InvestmentsListingAccountNumberLabel: "Investment No | ",

	LoanListing: "Facilities Listing",

	DepositDetail: "Deposit Details",
	InvestmentDetail: "Investment Details",
	LoanDetail: "Facilities Details",

	BuyAndSellTransactionsListing_NoData: "No transactions found",
	ReceivedProfitTransactionsListing_NoData: "No Profit transactions found",
	CashListing_NoData: "No Cash Transactions Found",
	DepositDetail_NoData: "No Data to Show",
	DepositListing_NoData: "No Deposit transactions found",
	InvestmentListing_NoData: "No Investments Found",
	HistoricalPayments_NoData: "No Historical Payments found",
	OutstandingPayments_NoData: "No Outstanding Payments found",
	NoDataToShow: "No Data to Show",
	OfferList_NoData: "No data available",
	DDL_NoData: "Not Found",
	Beneficiary_NoData: "No Beneficiary Found",

	DepositNo: "Deposit No. | ",
	AccountNo: "Account No.",
	InvestmentNo: "Investment No.",
	LoanNo: "Facility No.",
	Amount: "Amount",
	Description: "Description",
	Cash: "Cash",
	Deposit: "Deposit",
	Investment: "Investment",
	Loan: "Facilities",
	BuyAndSellTransactions_Status_Buy: "Buy",
	BuyAndSellTransactions_Status_Sell: "Sell",
	CashDetails_Filter_Debit: "Debit",
	CashDetails_Filter_Credit: "Credit",
	HistoricalPayments_PastDueSettlement: "Past Due Settlement",
	HistoricalPayments_SettlemenOfPayment: "Settlement of Payment",
	OutstandingPayments_PastDueSettlement: "Past Due Payment",
	OutstandingPayments_SettlemenOfPayment: "Payment of Facility",

	DepositsAndReceivedProfit: "Deposits and Received Profit",
	ClickToViewTheAccumulatedProfitReceived:
		"Click to view the accumulated profit received",
	CumulativeProfitReceived: "Cumulative Profit Received",

	ReceivedProfitText: "Profit Received",
	ViewReceivedProfit: "View Received Profit",
	ViewReceivedProfitNew: "View Received Profit Transacrion",
	ViewReceivedProfitTransactions: "Received Profit Transaction",
	BuyAndSellText: "Buy & Sell",
	BuyAndSellTransactionsText: "Buy & Sell Transaction",
	ViewBuySellTransactions: "View Buy & Sell Transaction",
	HistoricalPaymentsText: "Historical Payment",
	ViewHistoricalPayments: "View Historical Payment",
	OutstandingPaymentsText: "Outstanding Payment",
	ViewOutstandingPayments: "View Outstanding Payment",

	TransactionScreenFilter_None: "Select",
	TransactionScreenFilter_LastWeek: "Last week",
	TransactionScreenFilter_LastMonth: "Last month",
	TransactionScreenFilter_Last3Months: "Last 3 months",
	TransactionScreenFilter_CustomDate: "Select Custom Date",
	TransactionScreenFilter_Apply: "Apply",
	TransactionScreenFilter_StartDate: "Start Date",
	TransactionScreenFilter_EndDate: "End Date",
	TransactionScreenFilter_Amount: "Amount",
	TransactionScreenFilter_EQ: "Equal to",
	TransactionScreenFilter_GTE: "Greater than or equal to",
	TransactionScreenFilter_GT: "Greater than",
	TransactionScreenFilter_LTE: "Less than or equal to",
	TransactionScreenFilter_LT: "Less than",

	BankGuarantee: "Bank Guarantees",
	GuaranteeNo: "Guarantee No | ",
	BankGuaranteeListing: "Bank Guarantees Listing",
	BankGuaranteeDetails: "Bank Guarantees Details",
	InvestmentsDetailsTitle: "Investments Details",
	InvestmentsDetailsFromLabel: "From",
	InvestmentsDetailsLocationLabel: "Location",
	InvestmentsDetailsDateLabel: "Start Date",
	InvestmentsDetailsProfitRateLabel: "Expected Profit Rate",
	InvestmentsDetailsProfitDistributionLabel: "Profit Distribution Term",
	InvestmentsDetailsRecievedProfit: "Received Profit transaction",
	InvestmentsDetailsBuyAndSell: "Buy and sell transaction",
	InvestmentsDetailsLocationSample: "Doha, Qatar",

	NewsListingTitle: "Lesha Public News Listing",
	NewsListingDateSample: "08-08-2020",
	NewsListingTitleSample:
		"Lesha Bank acquires BSN Sports’ HQ Building “Varsity Brands” located in Texas",
	NewsDetailsTitle: "Lesha Public News Details",

	OffersListingTitle: "Offers",
	OffersDetailsTitle: "Offers Details",
	OffersDetailsButton: "Request to subscribe",

	OfferRequest: "Request to subscribe",
	OfferSubscriptionAmountLabel: "Subscription Amount",
	OfferSelectCurrencyLabel: "Subscription Currency",
	OfferButton: "Submit",

	RequestListingTitle: "Requests",
	RequestListingAddButton: "New Request",
	RequestListingFilterButton: "Filter",
	RequestListingSampleStatus: "Closed",
	RequestListingRemoveFilterButton: "Remove Filter",
	RequestListingFilterDate: "Date",
	RequestListingFilterStatus: "Request Status",
	RequestListingFilterType: "Request Type",
	RequestListingFilterSubmitButton: "SUBMIT REQUEST TO RM",
	RequestListingFilterSelect: "Select",
	RequestDetailsTitle: "Request Details",
	NewRequestTitle: "New Request",

	RequestFromLabel: "From",
	RequestToLabel: "To",
	RequestAuditorNameLabel: "Auditor Name",
	RequestTypeLabel: "Request Type",
	RequestButton: "Submit Request to RM",
	RequestSelectLabel: "Select",
	RequestAttachmentsLabel: "Attachment (PICTURE only)",
	RequestStatusLabel: "Status",

	AddAttachments: "Add Your Attachment",
	RemoveAttachments: "Remove Attachment",
	BrowseAttachments: "Browse",

	RequestOTPTitle: "Request Confirmation",
	RequestOTPHint: "You'll receive an sms with the OTP number",
	RequestOTPEnterOTP: "Enter OTP",
	RequestOTPResendOTP: "Resend OTP ?",
	RequestOTPButton: "Confirm",

	TransactionsListingTitle: "Transactions",
	TransactionsListingFilterButton: "Filter",
	TransactionsListingBeneficiariesButton: "Beneficiaries",
	TransactionsListingNewButton: "New Transaction",
	TransactionsListingTitleSample: "Transfer to Ahmed Mohamed",
	TransactionsListingStatus: "Cancelled",
	TransactionsDetailsTitle: "Transactions Details",
	NewTransactionTitle: "New Transaction",

	TransactionFromAccountLabel: "Transfer From Account",
	TransactionToAccountLabel: "Transfer To Account",
	TransactionCurrencyLabel: "Select Currency",
	TransactionAmountLabel: "Transaction Amount",
	TransactionBenficiaryLabel: "Beneficiary",
	TransactionDateLabel: "Request Date",
	TransactionDescriptionLabel: "Description / Purpose of Payment",
	TransactionTransferTypeLabel: "Transfer Type",
	TransactionRequestButton: "Submit",

	TransactionOTPTitle: "Transaction Confirmation",

	BeneficiariesListingTitle: "My Beneficiaries Listing",
	BeneficiariesListingIDLabel: "Beneficiaries ID | ",
	BeneficiariesListingCountrySample: "Qatar",

	NewBeneficiaryTitle: "Add New Beneficiary",
	EditBeneficiaryTitle: "Edit Beneficiary",
	BeneficiaryTransferTypeLabel: "Beneficiary Type",
	BeneficiaryIDLabel: "Beneficiary ID",
	BeneficiaryNickNameLabel: "Nickname",
	BeneficiaryQFBAccountOrIBAN: "Beneficiary Lesha account Number or IBAN",
	BeneficiaryBankLabel: "Beneficiary Bank",
	BeneficiarySwiftCodeLabel: "Beneficiary Bank Swift Code",
	BeneficiaryFullNameLabel: "Beneficiary Full Name",
	BeneficiaryAccountNumberLabel: "Beneficiary Account Number",
	BeneficiaryStreetNumberLabel: "Beneficiary Street Number",
	BeneficiaryCityLabel: "City",
	BeneficiaryCountryLabel: "Country",
	BeneficiaryBankCityLabel: "Beneficiary Bank City",
	BeneficiaryBankNameLabel: "Beneficiary Bank Name",
	BeneficiaryBankAddressLabel: "Beneficiary Bank Address",
	BeneficiaryIntermediaryBankLabel: "Intermediary Bank Name",
	BeneficiaryIntermediaryBankSwiftCodeLabel: "Intermediary Bank SWIFT Code",
	BeneficiaryForeignCurrencyLabel: "Beneficiary Account Currency",
	BeneficiaryRoutingNumberLabel: "Routing Number/Sort Code,if any",
	BeneficiaryIBANLabel: "Beneficiary IBAN",
	BeneficiaryIBANInternationalLabel: "Beneficiary IBAN (If Applicable)",

	BeneficiaryAddressLabel: "Beneficiary Address",
	BeneficiarySaveButton: "Save",
	BeneficiaryCancelButton: "Cancel",
	BeneficiaryEditButton: "Edit",
	BeneficiaryDeleteButton: "Delete",
	BeneficiaryInvalidIban: "Invalid Account Number or IBAN number",
	BeneficiaryIDLabelInternational: "Beneficiary ID(system assigned)",
	DeleteConfirmationTitle: "Confirmation Message",
	DeleteConfirmationBody: "Are you sure?",

	BeneficiariesDetailsTitle: "Beneficiary Details",

	DocumentsListingTitle: "Documents Listing",
	DocumentsDetailsTitle: "Document Details",
	DocumentsDetailsDownloadButton: "Download",
	DocumentTitleSample: "Document Number one",

	LiveHoldingTab: "Live Holdings",
	ExitediveHoldingTab: "Closed Holdings",

	RMLandingBankPositionsLabel: "Clients Request",
	RMLandingClientRequestDetailsLabel: "Clients Request Details",

	RMLandingClientsPortofoliosLabel: "Clients Portfolio ",
	RMLandingBankPositionsAnchorText: "View all requests to take action on them",
	RMLandingClientsPortofoliosAnchorText:
		"View all portfolio's of your clients here",

	ConfirmationDone: "Done",
	RecievedProfit: "Recieved Profit",

	SearchText: "Search",
	RMClientsRequestListingTitle: "Clients Request Listing",
	RMSampleTitle: "Account Statement",
	RMIsRegisterAccount: "Registered",
	RMIsNotRegisterAccount: "Not Registered",

	RMClienPortfolioListingTitle: "Clients Portfolios Listing",
	ClienPortfolioListingTitle: "Clients Portfolios",

	RMDetailsCIFLabel: "CIF",
	RMDetailsNameLabel: "Name",
	RMDetailsMobileLabel: "Mobile Number",
	RMDetailsRequestDetailsLabel: "Request Details",
	RMDetailsChangeRequestStatusLabel: "Change Request Status",
	RMDetailsbutton: "Save",

	InboxMessageListingTitle: "INBOX MESSAGES",
	InboxMessageListingFilterWithLabel: "Filter With",
	InboxMessageListingAsOfLabel: "As Of: ",
	InboxMessageDetailsTitle: "Inbox Message Details",
	InboxMessgaesSampleDate: "Wednesday 22July 2020",
	InboxMessageDetailsFromLabel: "From: ",
	InboxMessageDetailsToLabel: "To: ",
	InboxMessageDetailsPreviousLabel: "Previous Statements",

	ProductsAndOffersListingTitle: "Product and Offers",
	ProductsAndOffersListingNumberLabel: "Product Number | ",
	ProductsAndOffersListingNameLabel: "Product Name | ",
	ProductsAndOffersDetailsTitle: "Product and Offers Details",

	ContactUsTite: "Contact Us",
	ContactUsSubmitButton: "Submit",
	ContactUsClearButton: "Clear",
	ContactUsCreatedDateLabel: "Created Date",

	ContactUsNameLabel: "Name",
	ContactUsCountryLabel: "Country",
	ContactUsMobileLabel:
		"Mobile Number (Include country code, ex: +97412345678)",
	ContactUsEmailAddressLabel: "Email Address",
	ContactUsQueryLabel: "Query",
	SignupSuccessTitle: "Request Sent",
	SignupSuccessMessage:
		"Congratulations, you are now registered in Lesha Internet Banking",

	RequestSuccessTitle: "Request Sent",
	RequestSuccessMessage:
		"Your request has been submitted, we will get in touch with you soon",

	PasswordResetSuccessTitle: "Request Sent",
	PasswordResetSuccessMessage: "Your password reset is done successfully.",

	GenericErrorMessage: "Something went wrong, please try again later",
	NewRequestSubmitRequiredMessage:
		"Please enter all required field and make sure at least one field is filled",
	NewRequestSubmitInvalidDate: "To date must be greater than from date",
	ManagementLandingTitle: "Welcome Management",
	ManagementLandingItem1Title: "Bank Positions",
	ManagementLandingItem2Title: "Client's Portfolios",
	ManagementLandingItem1Desc: "Click here to see all bank position metrics",
	ManagementLandingItem2Desc: "View all bank's clients portfolios here",

	SettingsLandingTitle: "Settings",
	SettingsLandingNameSample: "Ahmed Hammad Nasser",
	SettingsLandingMobileSample: "+974 00000000",
	SettingsLandingEmailSample: "Ahmedhammad@gmail.com",

	SettingsLandingButton1: "Change Portfolio Currency",
	SettingsLandingButton2: "Change Password",
	SettingsLandingButton3: "Change One Time Password (OTP)",
	SettingsLandingButton4: "Change Communication Language",

	SettingSaveButton: "Save",
	SettingsCancelButton: "Cancel",
	ChangeCurrencyTitle: "Change Portfolio Currency",
	ChangeCurrencyLabelTitle: "Portfolio Currency",
	ChangeCurrencyQAR: "QAR",
	ChangeCurrencyUSD: "USD",
	ChangeCurrencyGPB: "GPB",
	ChangeCurrencyEUR: "EUR",

	ChangeOTPTitle: "Change One Time Password (OTP)",
	ChangeOTPLabel: "Choose OTP",
	ChangeOTPSMS: "SMS",
	ChangeOTPEmail: "Email",
	ChangeOTPSMSAndEMail: "SMS & Email",

	ChangeLanguageTitle: "Change Communication Language",
	ChangeLanguageLabel: "Choose Language",
	ChangeLanguageArabic: "Arabic",
	ChangeLanguageEnglish: "English",

	ChangePasswordTitle: "Change Password",
	ChangePasswordCurrentLabel: "Current Password",
	ChangePasswordNewLabel: "New Password",
	ChangePasswordConfirmLabel: "Confirm New Password",

	ConfirmationTitle: "Success",
	ConfirmationDesc: "Your action was saved successfully",
	SuccessMessageTitle: "Success",

	InvalidOTP: "Invalid OTP code, Please try again",

	NotificationsListingTitle: "Notifications",
	NotificationsDetailsTitle: "Notifications Details",
	NotificationsListingFilter: "Notification Type",
	ChangePassword_CurrentRequiredMsg: "Current password is required",
	ChangePassword_RequiredMsg: "Password is required",
	ChangePassword_InvalidationMsg:
		"Password must be minimum 7 characters & include at least 1 number and capital letter and should include at least 1 special character",
	ChangePassword_ConfirmRequiredMsg: "Confirm password is required",
	ChangePassword_MustMatchMsg: "Password doesn't match",

	DrawerAnonymoudSignIn: "Login",
	DrawerAnonymoudRegister: "Register",
	DrawerAnonymoudNews: "News",
	DrawerAnonymoudHome: "Home",
	DrawerAnonymoudeProductsAndOffers: "Products & Offerings",
	DrawerAnonymoudeContactUs: "Contact Us",
	DrawerAuthDocuments: "Documents",
	DrawerAuthSignout: "Logout",
	DrawerAuthHome: "Home",
	DrawerAuthContactUS: "Contact Us",
	DrawerAuthTerms: "Terms & Conditions",
	DrawerAuthInstructions: "Standard Instructions",
	DrawerAuthFaqs: "FAQ",
	DrawerAuthSettings: "Settings",

	SelectItem: "Select",

	FaqsTitle: "FAQ",
	PositionAnalysisTitle: "Position Analysis Page",
	BankPositionsTitle: "Bank Positions Page",

	ViewPositionAnalysisTitle: "View Position Analysis",

	ManagementLandingAssetsTitle: "Assets",
	ManagementLandingLiabilitiesTitle: "Liabilities",
	NotificationListingTitle: "Notifications",
	NotificationFilter_All: "All Notifications",
	RequestListingFilterStatusOption1: "Awaiting Review",
	RequestListingFilterStatusOption2: "Closed",
	RequestListingFilterStatusOption3: "In Progress",
	RequestListingFilterStatusOption4: "Cancelled",

	MessageFilter_Read: "Read",
	MessageFilter_UnRead: "Unread",
	MessageFilter_All: "All Messages",

	MyBeneficiariesAddNew: "New",
	landingPageInvaildBiometric: "Biometric Couldn't be verfied",
	selectPeriod: "Select a Period",
	Last6Weeks: "Past 6 Weeks",
	Last6Months: "Last 6 Months",
	Last6Quarters: "Last 6 Quarters",
	Last3Years: "Last 3 Years",
	CustomerInvestments: "Customer Investments",
	FinancingBalancesandratesPBandHC: "Loans",
	PastDuesPBandHC: "Past Dues",
	CustomersDepositsandrates: "Customers Deposits",
	BankCashBalances: "Bank Cash",
	MMFUNDBalances: "FI Borrowings",
	SUKUKBalances: "Sukuks Investments/Sukuks Funds",
	TreasuryPlacementsBalances: "Treasury Placements/MM Fund",
	TotalCash: "Total Cash",
	TotalInvestments: "Total Investments",
	TotalDeposits: "Total Deposits",
	TotalLoans: "Total Loans",
	BankGurantees: "Bank Gurantees",
	Networth: "Networth",
	TotalAssets: "Total Assets",
	TotalLiabilities: "Total Liabilities",
	TotalBalances: "Total Balances",
	NetAverageRate: "Net Average Rate",

	BankPositionsTotalAssetSub1: "Sukuk",
	BankPositionsTotalAssetSub2: "MM Fund",
	BankPositionsTotalAssetSub3: "Placements",
	BankPositionsTotalAssetSub4: "Past Dues",
	BankPositionsTotalAssetSub5: "Cash Balances",
	BankPositionsTotalAssetSub6: "Investments",
	BankPositionsTotalAssetSub7: "Loans",

	BankPositionsTotalLiabilitiesSub1: "Client’s Cash",
	BankPositionsTotalLiabilitiesSub2: "Customers Deposits",
	BankPositionsTotalLiabilitiesSub3: "FI Borrowings",

	StandardSettlementTitle: "Standard Settlement Instructions",
	StandardSettlementDesc:
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	ChangeLangConfirmationTitle: "Confirmation Message",
	ChangeLangConfirmationBody: "Do you want to switch to Arabic language?",
	Ok: "Ok",
	Cancel: "Cancel",
	NoConnectionAlertTitle: "Alert Message",
	NoConnectionAlertBody: "Please check your internet connection",
	Login_ArabicNumberHint:
		"Please use English Numbers to proceed with the login",
	SessionTimeOutMessage: "Your session time has ended, please login again",

	CorrespondentBank: "Correspondent Bank",
	Location: "Location",
	SwiftCode: "Swift Code",
	IBAN: "IBAN",
	NostroAccountNumber: "Nostro Account Number",

	SettingChangePasswordError: "Please enter valid details",
	ProductsAndOffersAddNew: "Add New",
	ProductsAndOffersNameLabel: "Name ",
	ProductsAndOffersArNameLabel: "Arabic Name ",
	ProductsAndOffersExpireLabel: "Expire Date ",
	ProductsAndOffersDescrLabel: "Description ",
	ProductsAndOffersArDescrLabel: "Arabic Description ",
	ProductsAndOffersSaveButton: "Save",
	ProductsAndOffersDeleteButton: "Delete",
	ProductsAndOffersSavedMessage: "Product has been saved successfully!",
	ProductsAndOffersDeletedMessage: "A Product has been deleted successfully!",

	NotificationsAddNew: "Add New",
	NotificationsDeleteButton: "Delete",
	NotificationsSubjectLabel: "Subject",
	deleteBtn: "Delete",
	deleteSure: "Are you sure?",
	deleteSureMessage: "You want to delete this item?",
	deleteYesBtn: "Yes, delete it!",
	cancelBtn: "Cancel",
	NotificationsCustomerNameLabel: "Customers ",
	NotificationsNameLabel: "Subject ",
	NotificationsArNameLabel: "Arabic Subject ",
	NotificationsExpireLabel: "Expire Date ",
	NotificationsDescrLabel: "Message Body ",
	NotificationsArDescrLabel: "Arabic Message Body ",
	NotificationsSaveButton: "Save",
	NotificationsSavedMessage: "Notification has been saved successfully!",

	OfferAddNew: "Add New",
	OfferNameLabel: "Title ",
	OfferArNameLabel: "Arabic Title ",
	OfferExpireLabel: "Expire Date ",
	OfferDescrLabel: "Description ",
	OfferArDescrLabel: "Arabic Description ",
	selectedOfferDetailsLabel: "Offer Details ",
	selectedOfferDetailsArLabel: "Arabic Offer Details ",
	OfferAttachment: "Attachment (PDF only)",
	OfferSaveButton: "Save",
	OfferDeleteButton: "Delete",

	OfferFileBrowseLabel: "Browse to upload a file",
	isEmptyText: " is empty.",
	moreThanLimit: "The total upload size limit is 2.5 MB.",
	supportedFileTypeError: "Selected file {*} is not supported.",
	formValidationMessage: "Please fill the needed fields before saving",
	sizeLabel: "Size",
	OfferSavedMessage: "Offer has been saved successfully!",
	OfferDeletedMessage: "An Offer has been deleted successfully!",
	searchPlaceholder: "Search",
	/***Web***/
	footerTxt:
		"Lesha Bank (Public) is authorized by QFCRA under license No.00091 and listed on the Qatar Stock Exchange Sitemap | ",
	footerTxtTerms: "Terms and Conditions",
	headerLogoTxt: "ONLINE BANKING PORTAL",

	registerLandingTitle: "NOT REGISTERED YET?",
	registerLandingInfo:
		"Make the most of your day by banking online with Lesha Bank.",
	registerLandingButton: "REGISTER NOW",
	registerTitle: "Register",
	registerStep1Hint: "Kindly fill the fields to register.",
	registerStep2Hint: "Kindly proceed your registration process.",
	registerStep3Hint: "Kindly fill the fields to register.",
	registerStep1StepCount: "Step 1 of 3",
	registerStep2StepCount: "Step 2 of 3",
	registerStep3StepCount: "Step 3 of 3",
	registerStep1Label1: "Lesha one time authorization code",
	registerStep1Label2: "Lesha CIF Number",
	registerStep1Label3: "Lesha registerd email",
	registerStep1Label4: "Lesha registerd Mobile",
	registerStep2Label1: "Enter Password",
	registerStep2Label2: "Confirm Password",
	registerStep3Label1: "Enter OTP",
	registerStep3Label2: "Resend OTP ?",

	registerStep1Button: "Next",
	registerStep2Button: "Next",
	registerStep3Button: "Complete Signup",

	appLandingTitle: "You can also access your account anytime from your mobile.",
	appLandingInfo: "Download the app Today",

	productAndOffersLandingTitle: "PRODUCTS AND OFFERS",
	productAndOffersLandingInfo: "Click here to see our latest!",
	productAndOffersLandingButton: "See More",
	productAndOffersListingLabel: "Product Number",

	contactUsLandingTitle: "For any inquiries contact us",
	contactUsLandingInfo: "Click here to get in touch for any query.",
	contactUsLandingButton: "Contact",

	OfferSubscriptionsTite: "Subscriptions Lists",
	OfferSubscriptionsDateLabel: "Subscriptions Date",
	OfferSubscriptionsRequestLabel: "Subscriptions Request",
	OfferSubscriptionsOfferLabel: "Subscribe for Offer ",
	OfferSubscriptionsAmountLabel: "Amount",
	OfferSubscriptionsCurrencyLabel: "Currency",

	pagingNext: "Next",
	pagingPrevious: "Previous",

	navigationUserMessage: "Welcome ",
	navigationItem1: "Holdings",
	navigationItem2: "Transfer",
	navigationItem3: "Offers",
	navigationItem4: "Requests",
	navigationItem5: "Messages",
	navigationItem6: "Notifications",

	topBarRightItem1: "Standard Settlements Instructions",
	topBarRightItem2: "Documents Listing",
	topBarRightItem3: "Settings",
	topBarRightItem4: "Contact Us",

	landingInboxTitle: "Inbox",
	landingMore: "View All",
	dummyTitle: "Title Goes Here",
	dummyDesc: "Lorem ipsum dolor sit amet, consectetur adipi.",
	dummyDate: "Wednesday 22 Nov 2020",
	dummyContent:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut",

	percentageLabel: "Percentage",
	exportToExcel: " Export to Excel",
	documentName: "Document Name",
	documentNameAr: "Arabic Document Name",
	documentPriority: "Display Priority",
	documentDate: "Document Date",
	documentShortDescription: "Short Description",
	documentShortDescriptionAr: "Arabic Short Description",
	documentDeletedMessage: "Document has been deleted successfully!",
	documentSavedMessage: "Document has been saved successfully!",
	documentListingPriority: "Priority",
	DrillBackButtonText: "Back",

	PortfolioNoInvestments: "No Investments Yet",
	PortfolioNoDeposits: "No Deposits  Yet",
	PortfolioNoFacilities: "No Facilities Found",

	OfferRequestSubmitMessage:
		'Your subscription request to the offer "[REPLACE ME]" has been submitted',
	ContactUsSelectCountry: "Select Country",
	OTPSentMessage: "An OTP has been sent successfully, kindly check.",

	fileDeleteConfirmMessage: "You want to delete this file?",
	NewBeneficiaryMsgTitle: "Beneficiary Sent",
	NewBeneficiaryMsgBody:
		"Your beneficiary has been sent successfully to your RM",

	AccessDeniedMsgTitle: "Access Denied",
	AccessDeniedMessage: "Your are not authorize to accesss this section.",

	ChartProfitRecieved: "Received Profit",
	ChartDepositsAmount: "Deposit Amount ",

	NoLiveHoldingsMesageDeposit: "No Live Holdings Yet for Deposit",
	NoLiveHoldingsMesageInvestment: "No Live Holdings Yet for Investment",
	NoClosedHoldingsMesageInvestment: "No Closed Holdings Yet for Investment",
	NoClosedHoldingsMesageDeposit: "No Closed Holdings Yet for Deposit",
	BreadcrumbLandingTitle: "Landing",
	BreadcrumbAdminTitle: "Admin",

	RMSampleAccount: "RM | ",
	CustomerSampleAccount: "CIF | ",
	ManagementSampleAccount: "Management | ",

	ChartDepositProfitRecieved: "Deposit Received Profit",
	ChartDrillDownHint: "Click to view the profit received",

	ContactUs_Mobile_Format_Validation_Message:
		"Mobile number should contain country code like +974xxxxxxxx",

	Signup_Autherization_Code: "Autherization code is not valid",
	Signup_CIF_Message: "Identification Number is not valid",
	Signup_Email_Message: "Email is not valid",
	Signup_Mobile_Number_Message: "Mobile Number is not Valid",

	Transactions_Amount_Validation: "Only number accepted",
	BeneficiaryInvalidAccountOrIban: "Invalid Account Number or IBAN",
	BeneficiaryInvalidIbanNumber: "Invalid IBAN",
	BeneficiaryInvalidSwiftCode: "Invalid Swift Code",

	NoDepositsHoldingsFound: "No Deposits Holdings Found",
	NoInvestmentsHoldingsFound: "No Investments Holdings Found",
	InvalidEmail: "Please enter a valid email address",

	TransactionWithinSameAccount:
		"Transaction within same account is not allowed",
	TransactionAmountLabel2: "Amount",
	RichTextImageUploadHint: "Image attachment should be less than 5MB",

	MobileNumberLengthError: "Mobile number must be at least 10 characters",

	RequestNumber: "Request Number | ",
	SendButtonText: "Send",
	AdminLandingTitle: "Welcome, Portal Admin",
	AmountExceedBalance: "Insufficient Balance",

	EarningTillDate: "Earnings To Date",
	MaxSizeAllowed: "Maximum allowed file size is 2.5 MB",
	ContactUs_Name_Format_Validation_Message: "Name Should be only letters",
	Preview: "View",
	Download: "Download",

	RelatedCashListing: "Related Customers Cash",
	RelatedDepoistListing: "Related Customers Deposits",
	RelatedInvestmentListing: "Related Customers Investments",
	RelatedName: "Name"
};

export default en;
